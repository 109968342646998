import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
	getBookingSelectApi,
	SaveSecondFormData,
} from "../../Redux/Auth/Auth_Action";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const EventTwo = (props) => {
	const Reduxstate = useSelector((state) => state?.Auth);
	const dispatch = useDispatch();
	let navigate = useNavigate();
	const [state, setState] = useState({
		first_name: Reduxstate?.token ? Reduxstate?.user?.first_name : "",
		last_name: Reduxstate?.token ? Reduxstate?.user?.last_name : "",
		phone: Reduxstate?.token ? Reduxstate?.user?.phone_no : "",
		email: Reduxstate?.token ? Reduxstate?.user?.email : "",
		location: "",
		specialrequest: "",
		companyName: "",
		city: "",
		guestno: "",
		nameofguest: "",
		allergies: "",
	});

	const [checkTerms, setCheckTerms] = useState(false);

	const InputVal = (e) => {
		let { name, value } = e.target;
		setState({ ...state, [name]: value });
	};

	const SubmitFormTwoData = () => {
		if (checkTerms) {
			if (
				!state.first_name ||
				!state.last_name ||
				!state.phone ||
				!state.email ||
				!state.location ||
				!state.city
			) {
				toast.error("Please fill all fields");
			} else {
				let Data = {
					first_name: state.first_name,
					last_name: state.last_name,
					phone_no: state.phone,
					email: state.email,
					company_name: state.companyName,
					address: state.location,
					city: state.city,
					// estimated_guests: state.guestno,
					description: state.specialrequest,
					guest_of_honour: state.nameofguest,
					allergies: state.allergies,
				};
				dispatch(SaveSecondFormData(Data));
				props.changeTab(3);
			}
		} else {
			toast.error("Please Agree to our terms and condition");
		}
	};

	return (
		<div className="EventTwo_Page">
			<div className="wizard-inner">
				<div className="maindiv_lambiline">
					<div className="lambi_line"></div>
					<div className="flx_stepsboxes">
						<div>
							<p className="bx-noactive">1</p>
							<p className="step1">Step 1</p>
						</div>
						<div>
							<p className="bx-active">2</p>
							<p className="step1">Step 2</p>
						</div>
						<div>
							<p className="bx-noactive">3</p>
							<p className="step1">Step 3</p>
						</div>
					</div>
				</div>

				<div className="hidden-tab"></div>
			</div>
			<form className="login-box">
				<div className="tab-content" id="main_form">
					<div className="tab-pane active" role="tabpanel" id="step2">
						<div className="banner-form-wrapper scnd-step">
							{/* <hr />
                            <div className="banner-form-content-wrapper wow animate__animated animate__fadeInDown">
                                <div>AASDASD</div>
                            </div> */}
							<div className="form-group">
								<input
									required
									onChange={InputVal}
									name="first_name"
									value={state.first_name}
									type="text"
									placeholder="Name"
									className="form-control"
								/>
							</div>
							<div className="form-group">
								<input
									required
									onChange={InputVal}
									name="last_name"
									value={state.last_name}
									type="tel"
									placeholder="Last Name"
									className="form-control"
								/>
							</div>
							<div className="form-group">
								<input
									required
									onChange={InputVal}
									name="phone"
									value={state.phone}
									type="tel"
									placeholder="Phone"
									className="form-control"
								/>
							</div>
							<div className="form-group">
								<input
									required
									onChange={InputVal}
									name="email"
									value={state.email}
									type="email"
									placeholder="Email"
									className="form-control"
								/>
							</div>

							<div className="form-group">
								<input
									required
									onChange={InputVal}
									name="location"
									value={state.location}
									type="text"
									placeholder="EVENT ADDRESS"
									className="form-control"
								/>
							</div>
							<div className="form-group">
								<input
									onChange={InputVal}
									name="companyName"
									value={state.companyName}
									type="text"
									placeholder="COMPANY NAME"
									className="form-control"
								/>
							</div>
							<div className="form-group">
								<input
									onChange={InputVal}
									name="city"
									value={state.city}
									type="text"
									placeholder="CITY"
									className="form-control"
								/>
							</div>

							<div className="form-group">
								<input
									onChange={InputVal}
									name="nameofguest"
									value={state.nameofguest}
									type="text"
									placeholder="GUEST OF HONOR"
									className="form-control"
								/>
							</div>

							{/* <div className="form-group">
                                <input
                                    onChange={InputVal}
                                    name="estimateguest"
                                    value={state.estimateguest}
                                    type="text" placeholder="NAME OF GUEST HONOUR" className="form-control" />
                            </div> */}

							{/* <div className="form-group">
                                <input
                                    onChange={InputVal}
                                    name="guestno"
                                    value={state.estimateguest}
                                    type="text" placeholder="ESTIMATED NO OF GUEST" className="form-control" />
                            </div> */}

							<div className="form-group">
								<input
									onChange={InputVal}
									name="allergies"
									value={state.allergies}
									type="text"
									placeholder="ANY ALLERGIES WE SHOULD KNOW ABOUT (Nuts, Dairy, Soy)"
									className="form-control text-nocase"
								/>
							</div>

							<div className="form-group">
								<textarea
									required
									onChange={InputVal}
									name="specialrequest"
									value={state.specialrequest}
									className="form-control"
									placeholder="Special Requests"
								></textarea>
							</div>
						</div>
						<input
							checked={checkTerms}
							onChange={(e) => {
								setCheckTerms(e.target.checked);
							}}
							type="checkbox"
						/>{" "}
						<label
							onClick={() => {
								navigate("/terms");
							}}
						>
							Accept our terms & conditions
						</label>
						<ul className="list-inline">
							<li>
								<button
									onClick={SubmitFormTwoData}
									type="button"
									className="default-btn btn scnd next-step mt-3"
								>
									Continue
								</button>
							</li>
						</ul>
					</div>
					<div className="continue-btn-below-text">
						<p>
							We will make sure you and your guests have a great event! Our
							truck will stay until everyone is served & satisfied. $150 deposit
							now. Our team will reach out a few days before to confirm details,
							special requests, & take final deposit
						</p>
					</div>
					<div className="clearfix"></div>
				</div>
			</form>
		</div>
	);
};

export default EventTwo;
