import axios from "axios";
import { toast } from "react-toastify";
import { ErrorAction } from "../Errors/Action";
import {
    headers, responseError, Login, SignUp, Contact,
    ProfileUpdate, headersWithImage, headersWithToken, ChangePassword,
    logoutDelToken, infoAfterLogin, GetFaqs, AboutGetAll,
    TruckCleanessAddress, LocationAddress, GetAllInit, site_url, GetMenuUrl, GetGalleryUrl, getBookingSelectUrl, getMoreTimeSelectUrl, CheckCurrentEventUrl, PayApiUrl, PostTestimonialUrl, GetTestimonialUrl, EnterEmailUrl, ResetPasswordUrl, GetSiteSettingsUrl, getBookingDataUrl, onDemandUrl, HomeAboutUsUrl, promoCodeApi
} from "../services/Apis";
import { showLoader } from "../services/Loader";
import { AFTER_LOGIN, SAVE_TOKEN, UPDATE_PROFILE } from '../services/Types';

export const saveInfo = (token, userInfo, checked, password, activePackage) => {
    // console.log("save token from action auth", password);
    return {
        type: SAVE_TOKEN,
        payload: { token, userInfo, checked, password, activePackage }
    }
}

export const saveAfterLoginInfo = (activePackage) => {
    // console.log('from auth action top area', activePackage);
    return {
        type: AFTER_LOGIN,
        payload: activePackage,
    }
}


export const UpdateProfileData = (e) => {
    // console.log("update profile data auth action", data)
    return {
        type: UPDATE_PROFILE,
        payload: { e }
    }
}

export const SaveFirstFormData = (e) => {
    // console.log("update profile data auth action", e)
    return {
        type: "SAVEFIRST_DATA",
        payload: { e }
    }
}

export const SaveSecondFormData = (e) => {
    // console.log("update profile data auth action", e)
    return {
        type: "SAVESECOND_DATA",
        payload: { e }
    }
}

export const setProfile = (e) => {
    return {
        type: "SET_PROFILE",
        payload: e
    }
}

export const getAllProducts = (e) => {
    return {
        type: "GET_ALL_PROFILE",
        payload: e
    }
}

export const singleProduct = (e) => {
    return {
        type: "SINGLE_PRODUCT",
        payload: e
    }
}

export const RemeberMe = (e) => {
    // console.log("data recieved",e)
    return {
        type: `REMEMBER_ME`,
        payload: { e }
    }
}

// softee apis starts

// get all faqs

export const getAllFaqsApi = () => {
    showLoader('flex');
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.get(`${GetFaqs}`, headers);
            showLoader('none');
            myResolve(res);
        } catch (e) {
            showLoader('none');
            console.log(e);
            myReject(e);
        }
    })
}

// save user info remember me

export const saveDetails = (data) => {
    // console.log('from auth action', data);
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            dispatch(RemeberMe(data))
            myResolve(true);
        } catch (e) {
            myReject(false);
        }
    })
}

// login api starts
export const LoginApi = (data, checked) => {
    // console.log('from auth service', data?.password);
    showLoader('flex');
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.post(`${Login}`, data, headers)
            // console.log("response from login", res);
            dispatch(saveInfo(res.data.response.data.token, res.data.response.data.user, checked, data?.password, res.data.response.data.activePackage))
            myResolve(true);
            showLoader('none');
        } catch (e) {
            let errorHandler = responseError(e);
            showLoader('none');
            console.log("Error found", e);
            dispatch(ErrorAction(errorHandler));
            myReject(false);
        }
    })
};

// singup api starts

export const SignupApi = (data) => {
    // console.log("from signup api", data);
    showLoader('flex');
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.post(SignUp, data, headers);
            // console.log("response from signup", res);
            toast.success("Verify Your Email To Access your account");
            showLoader('none');
            myResolve(true);
        } catch (e) {
            let errorHandler = responseError(e);
            dispatch(ErrorAction(errorHandler));
            // console.log('from auth action', errorHandler)
            showLoader('none');
            myReject(false);
        }
    });
};

// about us donw content api

export const AboutUsApi = () => {
    showLoader("flex");
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.get(AboutGetAll, headers);
            showLoader('none');
            myResolve(res);
        } catch (e) {
            showLoader('none');
            myReject(e);
            // toast.error('Network Error');
        }
    })
}

// home about section api

export const HomeAboutUsApi = () => {
    showLoader("flex");
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.get(HomeAboutUsUrl, headers);
            showLoader('none');
            myResolve(res);
        } catch (e) {
            showLoader('none');
            myReject(e);
            // toast.error('Network Error');
        }
    })
}

// truck cleaness api/

export const TruckCleanessApi = () => {
    showLoader('flex');
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.get(TruckCleanessAddress, headers);
            showLoader('none');
            myResolve(res);
        } catch (e) {
            showLoader('none');
            myReject(e);
            // toast.error("Network Error");
        }
    })
}

// location api

export const LocationApi = () => {
    showLoader('flex');
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.get(LocationAddress, headers);
            showLoader('none');
            myResolve(res);
        } catch (e) {
            showLoader('none');
            myReject(e);
            // toast.error("Network Error");
        }
    })
}


// get all location api

export const GetAllBranchesInit = () => {
    showLoader('flex');
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.get(GetAllInit, headers);
            showLoader('none');
            myResolve(res);
        } catch (e) {
            showLoader('none');
            myReject(e);
            // toast.error("Network Error");
        }
    })
}

// get breanches api

export const GetBranchApi = (id) => {
    showLoader('flex');
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.get(`${site_url}/GetLocation/${id}`, headers);
            showLoader('none');
            myResolve(res);
        } catch (e) {
            showLoader('none');
            myReject(e);
            // toast.error("Network Error");
        }
    })
}


// contact us page api

export const ContactApi = (data) => {
    // console.log("from contact api", data);
    showLoader('flex');
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.post(Contact, data, headers);
            // console.log("response from contact api", res);
            showLoader("none");
            myResolve(res);
        } catch (e) {
            let errorHandler = responseError(e);
            dispatch(ErrorAction(errorHandler));
            showLoader('none');
            myReject(e);
        }
    })
};


// get menu api

export const getMenuApi = () => {
    showLoader("flex");
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.get(GetMenuUrl, headers);
            // console.log("response from contact api", res);
            showLoader("none");
            myResolve(res);
        } catch (e) {
            let errorHandler = responseError(e);
            dispatch(ErrorAction(errorHandler));
            showLoader('none');
            myReject(e);
        }
    })
}

// get menu pagination

export const getMenuPaginateApi = (paginate_url) => {
    showLoader("flex");
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.get(paginate_url, headers);
            // console.log("response from contact api", res);
            showLoader("none");
            myResolve(res);
        } catch (e) {
            let errorHandler = responseError(e);
            dispatch(ErrorAction(errorHandler));
            showLoader('none');
            myReject(e);
        }
    })
}

// get gallery api

export const getGalleryApi = () => {
    showLoader("flex");
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.get(GetGalleryUrl, headers);
            // console.log("response from contact api", res);
            showLoader("none");
            myResolve(res);
        } catch (e) {
            let errorHandler = responseError(e);
            dispatch(ErrorAction(errorHandler));
            showLoader('none');
            myReject(e);
        }
    })
}

// get gallery paginate api

export const getGalleryPaginateApi = (paginate_url) => {
    showLoader("flex");
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.get(paginate_url, headers);
            // console.log("response from contact api", res);
            showLoader("none");
            myResolve(res);
        } catch (e) {
            let errorHandler = responseError(e);
            dispatch(ErrorAction(errorHandler));
            showLoader('none');
            myReject(e);
        }
    })
}



// update password api

export const UpdatePasswordApi = (data, reduxStateToken) => {
    // console.log(data, 'from update password api data auth action');
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.post(ChangePassword, data, headersWithToken(reduxStateToken));
            // console.log("response from signup", res);
            showLoader('none');
            myResolve(true);
        } catch (e) {
            let errorHandler = responseError(e);
            showLoader('none');
            dispatch(ErrorAction(errorHandler));
            myReject(false);
        }
    });
}

// logout api

export const LogoutApiDelToken = (token) => {
    // console.log("logout working from auth actions");

    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.get(logoutDelToken, headersWithToken(token));
            // console.log("logout working from auth actions inside apicall", res);
            myResolve(res);
        } catch (e) {
            console.log("error from auth error", e);
            myReject(e);
        }
    })
}



// update profile api starts

export const UpdateProfile = (data, token) => {
    // console.log('from contact api', token);
    showLoader('flex');
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.post(`${ProfileUpdate}`, data, headersWithImage(token));
            // console.log("response from update profile", res);
            dispatch(UpdateProfileData(res.data.response.data));
            showLoader("none");
            myResolve(true);
        } catch (e) {
            showLoader('none');
            console.log(e, ' from upate profile api');
            let errorHandler = responseError(e);
            dispatch(ErrorAction(errorHandler));
            myReject(false);
        }
    })
}

export const staticPagesApi = (slug) => {
    showLoader('flex');
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.get(`${site_url}/GetPages/${slug}`, headers);
            showLoader("none");
            myResolve(res);
        } catch (e) {
            showLoader('none');
            let errorHandler = responseError(e);
            dispatch(ErrorAction(errorHandler));
            myReject(false);
        }
    })
}

// get booking select api

export const getBookingSelectApi = () => {
    showLoader('flex');
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.get(getBookingSelectUrl, headers);
            showLoader("none");
            myResolve(res);
        } catch (e) {
            showLoader('none');
            let errorHandler = responseError(e);
            dispatch(ErrorAction(errorHandler));
            myReject(false);
        }
    })
}

// get more time api

export const GetMoreTimeApi = () => {
    showLoader('flex');
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.get(getMoreTimeSelectUrl, headers);
            showLoader("none");
            myResolve(res);
        } catch (e) {
            showLoader('none');
            let errorHandler = responseError(e);
            dispatch(ErrorAction(errorHandler));
            myReject(false);
        }
    })
}

// check current event api

export const CheckCurrentEventApi = (data, saveDataFirstStep) => {
    showLoader('flex');
    // console.log('save first data from step 1', saveDataFirstStep)
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.post(CheckCurrentEventUrl, data, headers);
            showLoader("none");
            dispatch(SaveFirstFormData(saveDataFirstStep))
            // dispatch(UpdateProfileData(res.data.response.data));
            myResolve(res);
        } catch (e) {
            showLoader('none');
            dispatch(SaveFirstFormData(saveDataFirstStep))
            // let errorHandler = responseError(e);
            // dispatch(ErrorAction(errorHandler));
            myReject(false);
        }
    })
}

export const payApi = (orderData, token) => {
    showLoader('flex');
    // console.log('order datappppppp ', orderData, token ? headersWithToken(token) : headers);
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.post(PayApiUrl, orderData, token ? headersWithToken(token) : headers);
            showLoader("none");
            // dispatch(UpdateProfileData(res.data.response.data));
            myResolve(res);
        } catch (e) {
            showLoader('none');
            let errorHandler = responseError(e);
            dispatch(ErrorAction(errorHandler));
            myReject(e);
        }
    })
}

// store testimonials api

export const PostTestimonialApi = (data, token) => {
    showLoader("flex");
    // console.log('data from posttest', data);
    // console.log('data from posttest', token);

    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.post(PostTestimonialUrl, data, headersWithImage(token));
            showLoader("none");
            myResolve(res);
        } catch (e) {
            showLoader('none');
            let errorHandler = responseError(e);
            dispatch(ErrorAction(errorHandler));
            myReject(e);
        }
    })
}

// get testimonails

export const GetTestimonialApi = () => {
    showLoader('flex');
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.get(GetTestimonialUrl, headers);
            showLoader("none");
            myResolve(res);
        } catch (e) {
            showLoader('none');
            let errorHandler = responseError(e);
            dispatch(ErrorAction(errorHandler));
            myReject(e);
        }
    })
}

// enter email api

export const EnterEmailApi = (data) => {
    showLoader('flex');
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.post(EnterEmailUrl, data, headers);
            showLoader("none");
            myResolve(res);
        } catch (e) {
            showLoader('none');
            let errorHandler = responseError(e);
            dispatch(ErrorAction(errorHandler));
            myReject(e);
        }
    })
}


export const ResetPasswordApi = (data) => {
    showLoader("flex");
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.post(ResetPasswordUrl, data, headers);
            showLoader("none");
            myResolve(res);
        } catch (e) {
            let errorHandler = responseError(e);
            showLoader("none");
            dispatch(ErrorAction(errorHandler));
            myReject(e)
        }
    })
}

// site settings 

export const GetSiteSettingsApi = () => {
    showLoader("flex");
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.get(GetSiteSettingsUrl, headers);
            showLoader("none");
            myResolve(res);
        } catch (e) {
            let errorHandler = responseError(e);
            showLoader("none");
            dispatch(ErrorAction(errorHandler));
            myReject(e)
        }
    })
}

// my bookings

export const getBookingDataApi = (token) => {
    showLoader("flex");
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.get(getBookingDataUrl, headersWithToken(token));
            showLoader("none");
            myResolve(res);
        } catch (e) {
            let errorHandler = responseError(e);
            showLoader("none");
            dispatch(ErrorAction(errorHandler));
            myReject(e)
        }
    })
}


// get booking pagination

export const getBookingPaginationApi = (paginate_url, token) => {
    showLoader("flex");
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.get(paginate_url, headersWithToken(token));
            // console.log("response from contact api", res);
            showLoader("none");
            myResolve(res);
        } catch (e) {
            let errorHandler = responseError(e);
            dispatch(ErrorAction(errorHandler));
            showLoader('none');
            myReject(e);
        }
    })
}

// get ondemand page api

export const onDemandApi = () => {
    showLoader("flex");
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.get(onDemandUrl, headers);
            showLoader("none");
            myResolve(res);
        } catch (e) {
            let errorHandler = responseError(e);
            dispatch(ErrorAction(errorHandler));
            showLoader('none');
            myReject(e);
        }
    })
}

// Promocode api

export const PromoCodeApi = (data) => {
    showLoader("flex");
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.post(promoCodeApi, data, headersWithToken);
            showLoader("none");
            myResolve(res);
            // console.log(res, 'mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm');
        } catch (e) {
            let message = typeof e.response !== "undefined" ? e.response.data.message : e.message;
            console.warn("error", message);
            let errorHandler = responseError(e);
            dispatch(ErrorAction(errorHandler));
            showLoader('none');
            myReject(e);
        }
    })
}

// info after login

export const InfoAfterLogin = (user_tok) => {
    showLoader("flex");
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.get(infoAfterLogin, headersWithToken(user_tok))
            myResolve(res?.data?.response);
            showLoader("none");
            // console.log("api response from auth action", res?.data?.response?.data);
            // dispatch(saveAfterLoginInfo(res?.data?.response?.data))
        } catch (e) {
            myReject(e);
            // let errorHandler = responseError(e);
            // dispatch(ErrorAction(errorHandler));
            showLoader("none");
        }
    })
}

// softee apis ends here