import React from "react";
import Footer from "../../ReuseableComponents/Footer";
import Header from "../../ReuseableComponents/Header";
import HeaderBanner from "../../ReuseableComponents/HeaderBanner";
import '../../Assests/Css/thank-you.css';
import { homeweare1, homeweare2 } from "../../MediaConstants/Index";

const ThankYou = () => {

    return (
        <div className="ThankYou_Page">
            <Header />
            <HeaderBanner name="Thank you" />
            <section className="thank_you">
                <div className="container">
                    <div className="row">
                        <div className="col-md-2"></div>
                        <div className="col-md-8">
                            <div className="thankyou_box">
                                <p><i>Thank you!</i> Your event has been booked successfully.
                                    {/* An email with details of your booking
                                    has been sent to you. */}
                                </p>
                            </div>
                        </div>
                        <div className="col-md-2"></div>
                    </div>
                </div>
                <img src={homeweare1} className="we1" alt="" />
                <img src={homeweare2} className="we2" alt="" />
            </section>
            <Footer />
        </div>
    )
}

export default ThankYou;