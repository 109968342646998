import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ResetPasswordApi } from '../../Redux/Auth/Auth_Action';
import { authlogin, authloginlogo } from '../../MediaConstants/Index';
import '../../Assests/Css/new-pass.css';

const NewPass = () => {
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const [state, setState] = useState({
        newPass: undefined,
        ConfirmnewPass: undefined,
        email: undefined,
    });
    const [terms, setTerms] = useState();

    const [searchParams, setSearchParams] = useSearchParams();
    let token_url = searchParams.get("token");
    // console.log("urlState", token_url);

    const InputVal = (e) => {
        let { name, value } = e.target;
        setState({ ...state, [name]: value });
    }

    const ResetFunction = (e) => {
        e.preventDefault();
        if (!terms) {
            toast.error("Accept our terms and conditions to proceed");
        } else {
            let data = {
                email: state.email,
                reset_token: token_url,
                password: state.newPass,
                confirm_password: state.ConfirmnewPass,
            }
            if (data.password === data.confirm_password && data.password.length >= 8) {
                dispatch((ResetPasswordApi(data)))
                    .then((res) => {
                        // console.log(res);
                        toast.success("Password change successfully");
                        setTimeout(() => {
                            navigate("/signin");
                        }, 4000);
                    })
                    .catch((err) => {
                        console.log(err);
                    })
            } else {
                toast.error('Password must be 8 characters or password and confirm password not matched ');
            }
        }
    }


    return (
        <section className="new-pass p-0">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-6 p-0">
                        <div className="passBox">
                            <div className="heading_wrapper">
                                <h2>New Password</h2>
                                <h4>Create a new Password</h4>
                            </div>
                            <form onSubmit={ResetFunction}>
                                <div className="form-group">
                                    <input
                                        onChange={InputVal}
                                        name="email" value={state.email} required
                                        type="email" className="form-control" placeholder="Enter Email" />
                                </div>
                                <div className="form-group">
                                    <input
                                        onChange={InputVal}
                                        name="newPass" value={state.newPass} required
                                        type="password" className="form-control" placeholder="New Password" />
                                </div>
                                <div className="form-group">
                                    <input
                                        onChange={InputVal}
                                        name="ConfirmnewPass" value={state.ConfirmnewPass} required
                                        type="password" className="form-control" placeholder="Re-enter New Password" />
                                </div>
                                <div>
                                    <div className="privacyDv">
                                        <p><span><input checked={terms} onChange={(e) => { setTerms(e.target.checked) }} className="mr-1" type="checkbox" /></span>By Creating Account you are agree to our <Link to="/termsandcondition">Terms & conditions</Link>
                                        </p>
                                    </div>
                                </div>
                                <button type="submit">Continue</button>
                            </form>

                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 p-0">
                        <div className="img-Dv">
                            <div className="imgBox">
                                <figure><img src={authlogin} alt="" /></figure>
                            </div>
                            <div className="LogoBox">
                                <figure>
                                    <Link to="/"><img src={authloginlogo} alt="" /></Link>
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default NewPass;