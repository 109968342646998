import React, { useState } from "react";
import { toast } from "react-toastify";
import { loadStripe } from "@stripe/stripe-js";
import { useSelector, useDispatch } from 'react-redux';
import { payApi } from "../Redux/Auth/Auth_Action";
import { useNavigate } from 'react-router-dom';
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
const stripePromise = loadStripe(
  "pk_live_51LhEEVL22PLN6na9g29t7MSJn1qoMNX64eHtgyc0iffEVxBYwS2d0e45DFhjhxIhGsDg6gEcekAQiMB1jZSGcIFz00q7akV3i9"
);
//

const CheckoutForm = (props) => {
  // softee work starts
  const Reduxstate = useSelector((state) => state.Auth);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  // console.log('zzzzzzzzzzzz', Reduxstate);
  // softee work ends

  //   const { StepTwoContinue, handleNext, setCardToken, cardToken } = props;
  const [formData, setFormData] = useState({});
  const [payProcessing, setPayProcessing] = useState(false);
  //   const [error, setError] = useState(false);
  const [done, setDone] = useState(false);

  const stripe = useStripe();
  const elements = useElements();
  const [paybutton, setPayButton] = useState(true);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (elements == null) {
      return;
    }
    const cardElement = elements.getElement(CardElement);
    const payload = await stripe.createToken(cardElement);
    // setCardToken(payload?.token?.id);
    // console.log("check", payload);
    if (payload?.token?.id) {

      let orderData = {
        orderdata: {
          slug: Reduxstate.firstSaveData.e.slug,
          event_date: Reduxstate?.firstSaveData?.e?.event_date,
          start_from: Reduxstate?.firstSaveData?.e?.start_from,
          finish_by: Reduxstate?.firstSaveData?.e?.finish_by || "00:00:00",
          price: Reduxstate?.firstSaveData?.e?.final_price,
          estimated_guests: Reduxstate?.firstSaveData?.e?.estimated_guests,
          first_name: Reduxstate?.secondSaveData?.e?.first_name,
          last_name: Reduxstate?.secondSaveData?.e?.last_name,
          phone_no: Reduxstate?.secondSaveData?.e?.phone_no,
          email: Reduxstate?.secondSaveData?.e?.email,
          company_name: Reduxstate?.secondSaveData?.e?.company_name,
          address: Reduxstate?.secondSaveData?.e?.address,
          city: Reduxstate?.secondSaveData?.e?.city,
          description: Reduxstate?.secondSaveData?.e?.description,
          guest_of_honour: Reduxstate?.secondSaveData?.e?.guest_of_honour,
          allergies: Reduxstate?.secondSaveData?.e?.allergies,
          user_id: Reduxstate.user.id ? Reduxstate.user.id : 0,
          _token: payload?.token?.id,
        }
      }

      // console.log("paaaaaaaaaaddddssssss", orderData);
      let UserToken = Reduxstate?.token;
      dispatch(payApi(orderData, UserToken))
        .then((res) => {
          // console.log("sucessfull event created", res);
          toast.success("Booking has been done successfully");
          setTimeout(() => {
            navigate('/thankyou');
          }, 2000);
        })
        .catch((e) => {
          console.log("not wokring zzzzzzzzzzzzzzzz", e);
        })
      return;
    } else {
      toast.error("Something Went Wrong");
      return;
    }
    setPayProcessing(true);
    setDone(true);
    setPayProcessing(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <CardElement
          onChange={(e) => {
            if (e.complete) {
              setPayButton(false);
            } else {
              setPayButton(true);
            }
          }}
        />
        <br />

        <ul className="list-inline">
          <li>
            <button
              type="submit"
              style={{ background: "#E51B23", color: "#fff", width: "100%", padding: "1rem", }}
              className="default-btn stripePay-Btn btn f-14 f-pop-med"
              disabled={!stripe || !elements || paybutton}
              onClick={props.handleClickOpen}
            >
              Pay Now
            </button>
          </li>
        </ul>
      </form>
    </>
  );
};

const StripeForm = (props) => {
  //   const {   } = props;
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm handleClickOpen={props.handleClickOpen}
      />

    </Elements>
  );
};

export default StripeForm;