import React from "react";
import { authlogin, authloginlogo } from '../../MediaConstants/Index';
// import '../../Assests/Css/login.css';
import '../../Assests/Css/verification.css';
import { Link } from "react-router-dom";

const Verification = () => {
    return (
        <section className="verification p-0">
        <div className="container-fluid">
            <div className="row align-items-center">
                <div className="col-lg-6 col-md-6 p-0">
                <div className="verificationBox">
                    <div className="heading_wrapper">
                        <h2>Verification</h2>
                        <h4>Enter the correct code.</h4>
                    </div>
                    <form >
                        <div className="form-group">
                            <div className="input-group">
                                <input type="number" maxlength="1" className="form-control" />
                                <input type="number" maxlength="1" className="form-control" />
                                <input type="number" maxlength="1" className="form-control" />
                                <input type="number" maxlength="1" className="form-control" />
                                <input type="number" maxlength="1" className="form-control" />
                                <input type="number" maxlength="1" className="form-control" />
                            </div>
                        </div>
                        <button type="submit">Submit</button>
                    </form>
                    <div className="privacyDv">
                        <p><span><input className="mr-1" type="checkbox"/></span>By Creating Account you are agree to our <Link href="/termsandcondition">Terms & conditions</Link>
                        </p>
                    </div>
                </div>
                </div>
                <div className="col-lg-6 col-md-6 p-0">
                    <div className="img-Dv">
                        <div className="imgBox">
                            <figure><img src={authlogin} alt=""/></figure>
                        </div>
                        <div className="LogoBox">
                            <figure><Link to="/"><img src={authloginlogo} alt=""/></Link></figure>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    )
}

export default Verification;