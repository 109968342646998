import React, { useState } from 'react';
import { Navigate, useLocation } from "react-router-dom";

import { useSelector, useDispatch } from 'react-redux';

const RequireAuth = ({ children }) => {

    const state = useSelector((state) => state.Auth);

    let location = useLocation();

    if (!state.token) return (
        <>
            <Navigate to="/login" state={{ from: location }} replace />
        </>)

    return children;
}

export default RequireAuth;