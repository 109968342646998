import React, { useEffect, useState } from "react";
// import { } from '../../MediaConstants/Index';
import Footer from '../../ReuseableComponents/Footer';
import Header from '../../ReuseableComponents/Header';
import HeaderBanner from '../../ReuseableComponents/HeaderBanner';
import '../../Assests/Css/on-demand.css';
import { demand1, demand2, demandcomming, demandgiftico, homeapple, homegoogle, homemobile, homemobile3, homeweare1, homeweare2, truckico } from "../../MediaConstants/Index";
import { useDispatch } from "react-redux";
import { onDemandApi } from "../../Redux/Auth/Auth_Action";

const OnDemand = () => {
    const [onDemand, setOnDemand] = useState();
    const dispatch = useDispatch();

    const onDemandFunc = () => {
        dispatch(onDemandApi())
            .then((res) => {
                // console.log(res?.data?.response?.data, 'res from ondemand page');
                setOnDemand(res?.data?.response?.data);
            })
            .catch((e) => {
                console.log(e, 'error from ondemand page')
            })
    }

    useEffect(() => {
        onDemandFunc();
        window.scroll(0, 0);
    }, []);

    return (
        <div className="OnDemand_Page">
            <Header />
            <HeaderBanner name="On Demand" />
            {/* <!-- who we are sec starts here --> */}
            <section className="who-we-are crunchy_sec">
                <div className="container">
                    <div className="heading_wrapper">
                        {/* <h3>on demand</h3> */}
                        {/* <h2>Lorem Ipsum Dolor sit amet</h2> */}
                    </div>
                    <div className="row pb-5 align-items-center">
                        <div className="col-lg-5">
                            <div className="img-wrapper wow animate__animated animate__fadeInLeft">
                                <figure>
                                    <img src={onDemand?.[1]?.media_url} className="img-fluid ondemand_image" alt="" />
                                </figure>
                            </div>
                        </div>
                        <div className="col-lg-1">
                        </div>
                        <div className="col-lg-6">
                            <div className="content-wrapper wow animate__animated animate__fadeInRight">

                                <div className="icon">
                                    {/* <figure><img src={onDemand?.[1]?.icon_url} className="img-fluid" alt="" /></figure> */}
                                </div>
                                <h2>{onDemand?.[1]?.title}</h2>
                                <div dangerouslySetInnerHTML={{ __html: onDemand?.[1].description }}>
                                </div>
                                <div className="button-group">
                                    {/* <a href="#" className="btn">On Demand</a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img src={homeweare1} className="we2" alt="" />
            </section>
            {/* <!-- who we are sec ends here -->

            <!-- comming starts here --> */}
            <section className="coming">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <figure>
                                <img src={homemobile3} className="img-fluid wow animate__animated animate__tada" alt="" />
                            </figure>
                        </div>
                        <div className="col-lg-8 align-self-center">
                            <div className="content-wrapper wow animate__animated animate__fadeInRight">
                                <h4>DOWNLOAD NOW</h4>
                                {/* <h1>Mister Softee On Demand <span>APP </span></h1> */}
                                <h1> Cones <span> App </span> </h1>
                                {/* <p>
                                    Download now mister softee on demand app
                                </p> */}
                                <a href="https://apps.apple.com/us/app/mister-softee-on-demand/id6443475272" >
                                    <img src={homeapple} className="img-fluid img_res_msall mr-2" alt="" />
                                </a>
                                <a href="https://play.google.com/store/apps/details?id=com.mistersofteeondemand">
                                    <img src={homegoogle} className="img-fluid img_res_msall" alt="" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <img src={demandcomming} className="ice" alt="" />
            </section>
            {/* <!-- comming ends here -->

            <!-- who we are sec starts here --> */}
            <section className="who-we-are crunchy_sec blue_sec bg-size-cover">
                <div className="container">
                    <div className="row pb-5 align-items-center">
                        <div className="col-lg-6">
                            <div className="content-wrapper wow animate__animated animate__fadeInRight">
                                {/* <div className="icon">
                                    <figure><img src={onDemand?.[0]?.icon_url} className="img-fluid" alt="" /></figure>
                                </div> */}
                                <h2>{onDemand?.[0]?.title}</h2>
                                <div dangerouslySetInnerHTML={{ __html: onDemand?.[0].description }}>
                                </div>
                                <div className="button-group">
                                    {/* <a href="#" className="btn">On Demand</a> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1"></div>
                        <div className="col-lg-5">
                            <div className="img-wrapper wow animate__animated animate__fadeInLeft">
                                <figure>
                                    <img src={onDemand?.[0]?.media_url} className="img-fluid ondemand_image" alt="" />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
                <img src={homeweare2} className="we2" alt="" />
            </section>
            {/* <!-- who we are sec ends here --> */}
            <Footer />
        </div>
    )
}

export default OnDemand;