import React, { useEffect, useState } from 'react';
import { homeweare1, homeweare2 } from '../../MediaConstants/Index';
import Footer from '../../ReuseableComponents/Footer';
import Header from '../../ReuseableComponents/Header';
import HeaderBanner from '../../ReuseableComponents/HeaderBanner';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import '../../Assests/Css/faqs.css';
import { useDispatch } from 'react-redux';
import { getAllFaqsApi } from '../../Redux/Auth/Auth_Action';
import { toast } from 'react-toastify';

const Faqs = () => {

    const [faqs, setFaqs] = useState(undefined);

    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const dispatch = useDispatch();

    const getAllFaqs = () => {
        dispatch(getAllFaqsApi())
            .then((res) => {
                setFaqs(res?.data?.response?.data);

            })
            .catch((e) => {
                console.log("errorrr", e);
            })
    }

    useEffect(() => {
        getAllFaqs();
        window.scroll(0, 0);

    }, []);


    return (
        <div className='GalleryPage FAQS_PAGESS'>
            <Header />
            <HeaderBanner name="FAQ'S" />
            <section className="faqs">
                <div className="container">
                    <div className="row">
                        <div className="col-md-1"></div>
                        <div className="col-md-10">
                            <div className="heading_wrapper">
                            <h3><span>More questions? We’ve got answers. Text, Call, or Email Us.</span></h3>
                                {/* <h3>faqs</h3>
                                <h2>Frequently Asked Question's </h2> */}
                                {/* <p>
                                    Welcome to the Official Website of Mister Softee! This site will allow you to locate your local
                                    Mister
                                    Softee franchise, learn about becoming a franchisee, learn about private parties with Mister
                                    Softee.
                                </p> */}
                            </div>  
                        </div>
                        <div className="col-md-1"></div>
                    </div>
                    <div className='row'>
                        {faqs?.map((item, index) => {
                            return (
                                <div key={index} className='offset-lg-1 col-lg-10 mb-3'>
                                    <Accordion className='accordian' expanded={expanded === index} onChange={handleChange(index)}>
                                        <AccordionSummary
                                            className='accordianSummary'
                                            expandIcon={<ExpandMoreIcon className='text-white' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <p>{item.title}</p>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordionDetails'>
                                            <div dangerouslySetInnerHTML={{
                                                __html: item.description
                                            }}></div>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            )
                        })}
                    </div>

                </div>
                <img src={homeweare2} className="we1" alt="" />
                <img src={homeweare1} className="we2" alt="" />
            </section>
            <Footer />
        </div>
    )
}

export default Faqs;