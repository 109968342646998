import { useEffect } from "react";
import AllRoutes from "./AllRoutes/AllRoutes";
// import ReactWOW from 'react-wow'
import WOW from 'wowjs';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ToastMessages from "./ReuseableComponents/ToastMessage";
import './Assests/Css/responsive.css';

function App() {
  useEffect(() => {
    new WOW.WOW({
      live: false
    }).init();
  }, [])

  return (
    <div>
      <>
        <div id="loader-full" className="loader-full">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
        <ToastMessages />
      </>
      <>
        <AllRoutes />
      </>
    </div>
  );
}

export default App;
