import React, { useState } from "react";
import { authlogin, authloginlogo } from '../../MediaConstants/Index';
// import '../../Assests/Css/login.css';
import '../../Assests/Css/enter-email.css';
import { useDispatch } from "react-redux";
import { EnterEmailApi } from "../../Redux/Auth/Auth_Action";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const EnterEmail = () => {

    const [email, setEmail] = useState();
    const [termsCheck, setTermsCheck] = useState(false);
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const SubmitEmail = (e) => {
        e.preventDefault();
        if (!termsCheck) {
            toast.error("Accept our terms and conditions to proceed");
        } else {

            let data = {
                email: email
            }
            dispatch(EnterEmailApi(data))
                .then((res) => {
                    toast.success("Reset password link has been sent to your email address");
                    setTimeout(() => {
                        navigate('/login');
                    }, 2000);
                })
                .catch((e) => {
                    console.log("error from forgot password page", e);
                })
        }
    }

    return (
        <section className="enter_email p-0">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-6 p-0">
                        <div className="emailBox">
                            <div className="heading_wrapper">
                                <h2>Enter Email</h2>
                                <h4>Please Enter Your Email Below<br />We will send you a recovery Link</h4>
                            </div>
                            <form onSubmit={SubmitEmail}>
                                <div className="form-group">
                                    <input required onChange={(e) => { setEmail(e.target.value) }} type="email" className="form-control" placeholder="Email" />
                                </div>
                                <div className="privacyDv">
                                    <p><span><input checked={termsCheck} onChange={(e) => { setTermsCheck(e.target.checked) }} className="mr-1" type="checkbox" /></span>By Creating Account you are agree to our <Link to="/termsandcondition">Terms & conditions</Link>
                                    </p>
                                </div>
                                <button type="submit">Send</button>
                            </form>

                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 p-0">
                        <div className="img-Dv">
                            <div className="imgBox">
                                <figure><img src={authlogin} alt="" /></figure>
                            </div>
                            <div className="LogoBox">
                                <figure><Link to="/"><img className="img_entr_email" src={authloginlogo} alt="" /></Link></figure>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default EnterEmail;