import { AFTER_LOGIN, REMEMBER_ME, SAVE_TOKEN, UPDATE_PROFILE } from '../services/Types';

const initialState = {
    user: {},
    token: null,
    addToCart: [],
    rememberMe: true,
    password: undefined,
    activePackage: null,
    firstSaveData: null,
    secondSaveData: null,
};

const Auth = (state = initialState, action) => {
    // console.log('auth reducer from reducer', action.payload);
    switch (action.type) {

        // saving token tfor authentication     

        case SAVE_TOKEN:
            // console.log("auth reducer jaaaaaaa", state.rememberMe);
            return {
                ...state,
                token: action.payload.token,
                user: action.payload.userInfo,
                rememberMe: action.payload.checked,
                password: action.payload.password,
                activePackage: action.payload.activePackage,
            }

        // case AFTER_LOGIN:
        //     console.log("from reducer auth reducer", action.payload);
        //     return {
        //         ...state,
        //         activePackage: action.payload,
        //     }

        // deleting token for logout functionality'

        case "DEL_TOKEN":
            // console.log(state.user, ' state user from auth reducer del token');
            return {
                ...state,
                token: null,
                activePackage: null
                // user : action.userInfo.user, 
            }

        case "SAVEFIRST_DATA":
            // console.log('SAVEFIRST_DATA', action.payload);
            return {
                ...state,
                firstSaveData: action.payload,
            }

        case "SAVESECOND_DATA":
            // console.log("SAVESECOND_DATA", action.payload);
            return {
                ...state,
                secondSaveData: action.payload,
            }

        case "UPDATE_PACKAGE":
            // console.log("wokreeed");
            return {
                ...state,
                activePackage: action?.payload?.e
            }

        case "DEL_PACKAGE":
            // console.log('del packagezzzzzzzzz', state);
            return {
                ...state,
                activePackage: null
            }

        // saving update profile data
        case "UPDATE_PROFILE":
            return {
                ...state,
                user: action.payload.e,
            }

        case `REMEMBER_ME`:
            // console.log('auth reducer from reducer', action.payload);
            return {
                ...state,
                rememberMe: [...action.payload]
            }

        case "INC":
            return {
                ...state,
                age: state.age + 1,
            }

        case "DEC":
            return {
                ...state,
                age: state.age - 1,
            }

        // getting data for profile page

        case "SET_PROFILE":
            return {
                ...state,
                profile: action.stateAll.user,
            }

        case "GET_ALL_PRODUCT":
            return {
                ...state,
                product: action.allProducts,
            }

        case "SINGLE_PRODUCT":
            // console.log("single product reducer",action )
            return {
                ...state,
                product: action.singleProduct,
            }

        case "ADD_TO_CART":
            return {
                ...state,
                addToCart: [...state.addToCart, action.addToCart],
            }

        case "DEL_FROM_CART":
            return {
                ...state,
                addToCart: action.delToCart
            }

        default: {
            // console.log("default working")
            return state
        }
    }
}

export default Auth;