import React, { useEffect, useState } from "react";
import '../../Assests/Css/style.css';
import { aboutabt1, abouticon1, abouticon2, abouticon3, aboutinnerbg, aboutlocation, aboutwatermark, homeweare1, homeweare2 , softeeee } from "../../MediaConstants/Index";
import Footer from "../../ReuseableComponents/Footer";
import Header from "../../ReuseableComponents/Header";
import '../../Assests/Css/about.css';
import OurLocation from "./OurLocation";
import HeaderBanner from "../../ReuseableComponents/HeaderBanner";
import { AboutUsApi, LocationApi, TruckCleanessApi } from "../../Redux/Auth/Auth_Action";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';

const About = () => {

    const [about, setAbout] = useState();
    const [truckCleaness, setTruckCleaness] = useState();

    const dispatch = useDispatch();

    const GetAboutTopContent = () => {
        dispatch(TruckCleanessApi())
            .then((res) => {
                setTruckCleaness(res.data.response.data);
            })
            .catch((e) => {
                console.log(e);
            })
    }

    const GetAboutContent = () => {
        dispatch(AboutUsApi())
            .then((res) => {
                // console.log(res, '33333333333333333333333333333')
                setAbout(res.data.response.data);
            })
            .catch((e) => {
                console.log(e);
            })
    }

    useEffect(() => {
        GetAboutTopContent();
        GetAboutContent();
        window.scroll(0,0);
        // GetAboutLocation();
    }, [])

    return (
        <div className="AboutPage">
            <Header />
            {/* <!-- Universal Banner Start Here --> */}
            {/* <HeaderBanner name="About Us" /> */}
            <section className="universal_banner">
                <div className="container-fluid p-0">
                    <div className="banner_img">
                        <figure><img src={softeeee} className="img-fluid" /></figure>
                    </div>
                    <div className="page-head">
                        <h2>About Us</h2>
                    </div>
                </div>
            </section>
            {/* <!-- Universal Banner End Here -->
            <!-- Watermark Image --> */}
            <div className="watermark">
                <figure><img src={aboutwatermark} className="img-fluid" /></figure>
            </div>
            {/* <!-- Universal Banner End Here --> */}

            {/* <!-- who we are sec starts here --> */}
            {/* about */}
            <section className="who-we-are">
                <div className="container">
                    {about?.map((item, index) => {
                        if (item.type === "top") {
                            if ((index + 1) % 2 === 0) {
                                return (
                                    <div key={index} className="row pb-5 pb-rez-noo">
                                              <div className="col-lg-6">
                                            <div className="content-wrapper wow animate__animated animate__fadeInRight">
                                                <figure>
                                                    <img src={item.image_url} className="img-fluid" />
                                                </figure>
                                                <h2 className="mb-0">{item.title.slice(0,13)}</h2>
                                                <h2 className="mb-0">{item.title.slice(13,29)}</h2>
                                                <h2 className="mb-0">{item.title.slice(29)}</h2>
                                                <div dangerouslySetInnerHTML={{
                                                    __html: item.description
                                                }}></div>
                                            </div>
                                        </div>
                                        <div className="col-lg-1">
                                        </div>
                                        <div className="col-lg-5">
                                            <div className="img-wrapper wow animate__animated animate__fadeInLeft">
                                                <figure>
                                                    <img src={item.cover_image_url} className="img-fluid h-settt" alt="" />
                                                </figure>
                                            </div>
                                        </div>
                                    </div>
                                )
                            } else {
                                return (
                                    <div key={index + 1000} className="row pb-5 pb-rez-noo">
                                        <div className="col-lg-5">
                                            <div className="img-wrapper wow animate__animated animate__fadeInLeft">
                                                <figure>
                                                    <img src={item.cover_image_url} className="img-fluid h-settt" alt="" />
                                                </figure>
                                            </div>
                                        </div>
                                        <div className="col-lg-1">
                                        </div>
                              



                                        <div className="col-lg-6">
                                            <div className="content-wrapper wow animate__animated animate__fadeInRight">
                                                <figure><img src={item.image_url} className="img-fluid" /></figure>
                                                <h2>{item.title}</h2>
                                                <div dangerouslySetInnerHTML={{
                                                    __html: item.description
                                                }}></div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        }
                    })}
                </div>
                <img src={homeweare2} className="we1" alt="" />
                <img src={homeweare1} className="we2" alt="" />
            </section>
            {/* <!-- who we are sec ends here --> */}
            {/* <section>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <a href="https://blog.thebristal.com/mr.-softee-visits-garden-city"><h3>Check out Mr. Softee Visits Garden City</h3> </a>
                        </div>
                        <div className="col-lg-12 text-center">
                            <TwitterTweetEmbed
                                className="tweet_secc"
                                tweetId={'1201566761444876291'}
                            />
                        </div>

                    </div>
                </div>
            </section> */}

            {/* <!-- who we are sec starts here --> */}
            {/* <section className="who-we-are crunchy_sec">
                <div className="container">
                    <div className="heading_wrapper">
                        <h3>delight in every bite</h3>
                        <h2>Crispy, Crunchy & Nutty</h2>
                    </div>
                    {about?.map((item, index) => {
                        if (item.type === "bottom") {
                            return (
                                <div key={index + 100} className="row pb-5">
                                    <div className="col-lg-5">
                                        <div className="img-wrapper wow animate__animated animate__fadeInLeft">
                                            <figure>
                                                <img src={item.cover_image_url} className="img-fluid ht-img-home" alt="" />
                                            </figure>
                                        </div>
                                    </div>
                                    <div className="col-lg-1">
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="content-wrapper wow animate__animated animate__fadeInRight">
                                            <img width={100} className="abt-immngaz" src={item.media_url} />
                                            <h2>{item.title}</h2>
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: item.description
                                                }}></div>
                                            <div className="button-group">
                                                <Link to="/contact" className="btn">Contact us</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    })}
                </div>
            </section> */}
            {/* <!-- who we are sec ends here --> */}

            {/* <!-- Location Sec Start Here --> */}
            <section className="location_sec">
                <div className="container">
                    <div className="heading_wrapper">
                        <h3>our locations</h3>
                        <h2>Are you searching for “the best Ice Cream truck near me?” </h2>
                    </div>
                    <OurLocation />
                </div>
            </section>
            {/* <!-- Location Sec End Here --> */}
            <Footer />
        </div>
    )
}

export default About;