import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { footericecream, footerlogo, footerSpiderman } from "../MediaConstants/Index";
import { GetSiteSettingsApi } from "../Redux/Auth/Auth_Action";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import '../Assests/Css/header.css';

const Footer = () => {
    let navigate = useNavigate();
    const [siteData, setSiteData] = useState(null);
    const dispatch = useDispatch();
    const getSiteSetting = () => {
        dispatch(GetSiteSettingsApi())
            .then((res) => {
                // console.log('pppppppppppppppppppppppp',res?.data?.response?.data[0]);
                setSiteData(res?.data?.response?.data[0])
            })
            .catch((e) => {
                console.log(e);
            })
    }

    useEffect(() => {
        getSiteSetting();
    }, []);

    // console.log("baaaaaaaam", siteData);
    return (
        <div className="FooterComp">
            <footer className="footer">
                <div className="container">
                    <div className="row pill">
                        <div className="col-lg-4">
                            <div className="flx-line-bx">
                                <div className="footer-widget-1-wrapper">
                                    <Link to="/" className="mb-4">
                                        <img src={siteData?.media_url} className="img-fluid ftr-logoooooooo mb-4" alt="" />
                                    </Link>
                                    <div className="footer-links-wrpper">
                                        <ul>
                                            {/* <li>{siteData?.address}</li> */}
                                            <li className="mb-2 txt-no-trnsfrm"><EmailOutlinedIcon className="mr-2"/><a href={`mailto:${siteData?.contact2_email}`}>{`${siteData?.contact2_email}`}</a></li>
                                            <li className="txt-no-trnsfrm"><LocalPhoneOutlinedIcon className="mr-2"/><a href="tel:+032 3456 7890">{siteData?.contact_phone}</a></li>
                                        </ul>
                                        <div>
                                            <a href={`${siteData?.facebook}`} target='_blank'>
                                                <FacebookIcon className="ico_foooter cursor_pointer mr-2" />
                                            </a>
                                            <a href={`${siteData?.instagram}`} target='_blank'>
                                                <InstagramIcon className="ico_foooter cursor_pointer mr-2" />
                                            </a>
                                            {/* <a href={`${siteData?.twitter}`} target='_blank'>
                                                <TwitterIcon className="ico_foooter cursor_pointer" />
                                            </a>  */}
                                        </div>
                                    </div>
                                </div>
                                <div className="rightline-ftr">

                                </div>
                            </div>

                        </div>
                        <div className="col-lg-5">
                            <div className="flx-line-bx">
                                <div className="footer-widget-2-wrapper">
                                    <div className="flx-ftr-qck-lnk">
                                        <h4>Quick Links</h4>
                                        {/* <Link to="/ondemand" className="btn demand bt-pnk-top-10">On demand</Link> */}
                                        {/* <Link to="/ondemand" ><h4>On demand</h4></Link> */}
                                    </div>
                                    <div className="footer-links-wrapper">
                                        <ul>
                                            {/* <div className="row">
                                                <div className="col-lg-4">

                                                </div>
                                                <div className="col-lg-4">

                                                </div>
                                                <div className="col-lg-4">

                                                </div>

                                            </div> */}
                                            <li><Link to="/">Home</Link></li>
                                            <li><Link to="/about">About Us</Link></li>
                                            <li><Link to="/menu">Menu</Link></li>
                                            <li><Link to="/faq">FAQ’s </Link></li>
                                            <li><Link to="/ondemand">On demand</Link></li>
                                            <li><Link to="/login">Login/Signup</Link></li>
                                            <li><Link to="/bookevent">Book an Event</Link></li>
                                            <li><Link to="/gallery">Gallery Page</Link></li>
                                            <li><Link to="/truck">Truck cleanliness</Link></li>
                                            <li><Link to="/contact">Contact</Link></li>
                                            <li><Link to="/termsandcondition">Terms & Conditions</Link></li>
                                            <li><Link to="/privacypolicy">Privacy policy</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="rightline-ftr margn-lft-3rm">

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 pl-5">
                            <div className="footer-widget-3-wrapper">
                                <h4>Address</h4>
                                <div className="footer-links-wrapper">
                                    <ul>
                                        {/* <li><a href="#">updated by admin | {siteData?.updated_at.slice(0, 10)}</a></li> */}
                                        <li>{siteData?.address}  </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="copyright">
                    {/* <img src="img/Spiderman-Popsicles.png" className="copy1" alt="">  */}
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-2">
                                <img src={footerSpiderman} className="img-fluid copy1" alt="" />
                            </div>
                            <div className="col-lg-4 align-self-end">
                                <span className="rights-text">{siteData?.footer_sentence}</span>
                            </div>
                            <div className="col-lg-4 text-right align-self-end">
                                <Link to="/termsandcondition">Terms And Conditions</Link> &nbsp; &nbsp;
                                <Link to="/privacypolicy">Privacy Policy</Link>
                            </div>
                            <div className="col-lg-2">
                                <img src={footericecream} className="img-fluid copy2" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default Footer;