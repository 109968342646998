export const headers = () => {
	return {
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			"Access-Control-Allow-Origin": "*",
		},
	};
};

export const headersWithToken = (token) => {
	return {
		headers: {
			Accept: "application/json",
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
			"Access-Control-Allow-Origin": "*",
		},
	};
};

export const headersWithImage = (token) => {
	return {
		headers: {
			Accept: "application/json",
			Authorization: `Bearer ${token}`,
			"Content-Type": "multipart/form-data",
			"Access-Control-Allow-Origin": "*",
		},
	};
};

export const responseError = (e) => {
	let data = [];
	if (e.response.data.statusCode === 401) {
		data.push({ message: e.response.data.message, color: "warning" });
	} else if (e.response.data.statusCode === 409) {
		e.response.data.errors.map((val) => {
			data.push({ message: val, color: "warning" });
		});
	} else if (
		e.response.data.statusCode === 422 ||
		e.response.data.statusCode === 405
	) {
		data.push({ message: e.response.data.errors, color: "warning" });
	} else if (e.response.data.statusCode === 400 && e.response.data.errors) {
		for (let key in e.response.data.errors) {
			if (e.response.data.errors.hasOwnProperty(key)) {
				data.push({
					message: e.response.data.errors[key][0],
					color: "warning",
				});
			}
		}
	} else if (e.response.data.statusCode === 404) {
		data.push({ message: e.message, color: "info" });
	} else {
		data.push({ message: e.message, color: "danger" });
	}
	return data;
};

// export const site_url = 'https://admin.mistersofteeondemand.com/api';
// export const site_url = `https://admin.conesapp.com/api`;
export const site_url = `http://mr-softee.developer-ourbase.com/api`;
export const Login = `${site_url}/auth/login`;
export const SignUp = `${site_url}/auth/signup`;
export const GetFaqs = `${site_url}/faqs`;
export const AboutGetAll = `${site_url}/GetAboutUs`;
export const GetLocation = `${site_url}/GetAboutUs`;
export const TruckCleanessAddress = `${site_url}/GetTruckcleanliness`;
export const LocationAddress = `${site_url}/GetLocation`;
export const GetAllInit = `${site_url}/GetBranches`;
export const Contact = `${site_url}/contact`;
export const GetMenuUrl = `${site_url}/GetMenuItem`;
export const GetGalleryUrl = `${site_url}/GetGallery`;
export const ChangePassword = `${site_url}/user/changePassword`;
export const ProfileUpdate = `${site_url}/user/update`;
export const getBookingSelectUrl = `${site_url}/GetPackage`;
export const getMoreTimeSelectUrl = `${site_url}/GetMoreTime`;
export const CheckCurrentEventUrl = `${site_url}/CheckCurrentEvent`;
export const PayApiUrl = `${site_url}/subscribe`;
export const PostTestimonialUrl = `${site_url}/StoreTestimonial`;
export const GetTestimonialUrl = `${site_url}/GetTestimonial`;
export const EnterEmailUrl = `${site_url}/auth/forgot-password`;
export const ResetPasswordUrl = `${site_url}/auth/reset-password`;
export const GetSiteSettingsUrl = `${site_url}/GetSiteSettings`;
export const getBookingDataUrl = `${site_url}/user/GetUserBooking`;
export const logoutDelToken = `${site_url}/user/logout`;
export const onDemandUrl = `${site_url}/GetOndemand`;
export const HomeAboutUsUrl = `${site_url}/home`;
export const infoAfterLogin = `${site_url}/user/info`;
export const promoCodeApi = `${site_url}/storeFreeCone`;

// softee url ends

export const ForgotPassword = `${site_url}/auth/forgot-password`;
export const StaticPages = `${site_url}/showPages`;
export const ShowTicker = `${site_url}/showTicker`;
export const ShowPodcast = `${site_url}/showPodcast`;
export const ShowBlog = `${site_url}/showBlog`;
export const ShowPremiumTips = `${site_url}/tip/getPremiumTip`;
export const ShowFreeTips = `${site_url}/tip/getFreeTip`;
export const showTestimonial = `${site_url}/showTestimonial`;
export const ShowPackages = `${site_url}/showPackage`;
export const showCategory = `${site_url}/showCategory`;
export const addCard = `${site_url}/user/AddCard`;
export const subscribe_url = `${site_url}/user/subscribe`;
export const cancelSubscription = `${site_url}/user/UpgradeSubscribe`;
export const UpgradeSubs = `${site_url}/user/CancelSubscription`;
export const ResetPassword = `${site_url}/auth/reset-password`;
export const newsLetterUrl = `${site_url}/newsletterStore`;
