import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { profileimg } from '../../MediaConstants/Index';
import { useNavigate } from 'react-router-dom';
import { LogoutApiDelToken, UpdatePasswordApi } from '../../Redux/Auth/Auth_Action';

const Security = () => {
    const state = useSelector((state) => state.Auth);
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const [secData, setSecData] = useState({
        pass: "",
        newPass: "",
        CnewPass: "",
    });

    const InputVal = (e) => {
        let { name, value } = e.target;
        setSecData({ ...secData, [name]: value })
    }

    // console.log(state);

    const LogoutFunc = () => {
        dispatch(LogoutApiDelToken(state.token))
            .then((res) => {
                navigate("/");
                dispatch({ type: "DEL_TOKEN" });
                // console.log("sucessfull logout");
            })
            .catch((e) => {
                navigate("/");
                console.log("unsucessfull logout");
            })
    }

    const SubmitData = () => {
        if (secData.newPass < 8) {
            toast.error("Password must be 8 character");
        }
        else if (secData.newPass !== secData.CnewPass) {
            toast.error("Password and Confirm Password not matched");
        }
        else {
            let Data = {
                current_password: secData.pass,
                password: secData.newPass,
                confirm_password: secData.CnewPass
            }
            dispatch(UpdatePasswordApi(Data, state.token))
                .then((res) => {
                    toast.success("password updated successfully");
                    setTimeout(() => {
                        LogoutFunc();
                    }, 1500);
                })
                .catch((e) => {
                    console.log('eeeeeeeeee', e)
                })
        }
    }
    
    return (
        <div className='Security_page'>
            <div className="tab-pane fade show active" id="v-pills-profile" role="tabpanel"
                aria-labelledby="v-pills-profile-tab">
                <div className="security_tab">
                    <div className="profile-img-tab-wrapper">
                        <div className="img-content-wrapper">
                            <figure>
                                <img src={state.user.profile_picture} className="img-fluid profile-img img-prf-rnddd" alt="" />
                                {/* <button><i className="fa fa-plus"></i></button> */}
                            </figure>
                            <div className="profile-name-content">
                                <h4>{state.user.first_name} {state.user.last_name}</h4>
                                {/* <h5>Lorem Ipsum</h5> */}
                            </div>
                        </div>
                        {/* <!-- <div className="profile-button-wrappper">
                    <button className="btn edit-profile">Edit Profile</button>
                </div> --> */}
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label for="">Password</label>
                                <input
                                    value={secData.pass}
                                    onChange={InputVal}
                                    name="pass"
                                    placeholder='.....'
                                    type="password" className="form-control" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label for="">New Password</label>
                                <input
                                    value={secData.newPass}
                                    onChange={InputVal}
                                    name="newPass"
                                    placeholder='.....'
                                    type="password" className="form-control" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label for="">Confirm New Password</label>
                                <input
                                    value={secData.CnewPass}
                                    onChange={InputVal}
                                    name="CnewPass"
                                    placeholder='.....'
                                    type="password" className="form-control" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group mt-5">
                                <button onClick={SubmitData} className="btn">Save Changes</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Security;