import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { homeno } from '../../MediaConstants/Index';
import { TruckCleanessApi } from '../../Redux/Auth/Auth_Action';

const TruckCleanessHome = () => {
    const [truckDaata, setTruckData] = useState();
    const dispatch = useDispatch();

    const getTruckData = () => {
        dispatch(TruckCleanessApi())
            .then((res) => {
                setTruckData(res.data.response.data);
            })
            .catch((e) => {
                console.log(e);
            })
    }
    useEffect(() => {
        getTruckData();
    }, []);

    return (
        <div className="row">
            {truckDaata?.slice(0, 5).map((item, index) => {
                if (item.type === "sub_banner") {
                    return (
                        <div key={index} className="col-lg-6 pb-4">
                            <div className="row">
                                <div className="col-lg-2">
                                    <div className="img-wrapper wow animate__animated animate__fadeInLeft">
                                        <figure>
                                            <img src={item.image_url} className="img-fluid" alt={`${item.title}`} />
                                        </figure>
                                    </div>
                                </div>
                                <div className="col-lg-10">
                                    <div className="content-wrapper wow animate__animated animate__fadeInDown">
                                        <h2>{item.title}</h2>
                                        <div className='all-white' dangerouslySetInnerHTML={{ __html: item.description }}
                                        ></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            })}
        </div>
    )
};

export default TruckCleanessHome;