import React from 'react';
import { Link } from 'react-router-dom';
import '../../Assests/Css/cong.css';
import { authloginlogo, ondemand_logo } from '../../MediaConstants/Index';
import Footer from '../../ReuseableComponents/Footer';

const Congrats = () => {
    return (
        <div className='Congrats_Page'>
            <div className='Congrats'>
                <section className="congrats-sec">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="cong-logo">
                                    <Link to="/">
                                        <img className="img-fluid" src={ondemand_logo} alt="" />
                                    </Link>
                                </div>

                                <div className="congrats-all mb-5">
                                    <h2>Congrats!</h2>
                                    <p>
                                        <img className="img-fluid" src="img/check.png" alt="" />
                                        You have successfully submitted your information.
                                        Add the promo code in your email to our app for a free cone on your first on demand order!
                                        {/* <br />
                                        Now you can Avail Free Topping from
                                        <span> Mister Softee</span>
                                        <br /> Your Code
                                        is <a href="tel:09ZZ786Z1">09ZZ786Z1</a> */}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >
            </div>
            <Footer />
        </div>
    )
}

export default Congrats