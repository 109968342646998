import React, { useEffect, useState } from 'react';
import { homeMask, homequote } from '../../MediaConstants/Index';
import Slider from 'react-slick/lib/slider';
import '../../Assests/Css/Testimonialzaam.css';
import { useDispatch } from 'react-redux';
import { GetTestimonialApi } from '../../Redux/Auth/Auth_Action';
// Testimonialzaam

const TestimonialItems = () => {
    const [testimonial, setTestimonial] = useState();
    const dispatch = useDispatch();
    const getTestimonials = () => {
        dispatch(GetTestimonialApi())
            .then((res) => {
                // console.log('pouuuuuuuuuuuuuuwonnnnnn',res.data.response.data.data);
                setTestimonial(res.data.response.data.data);
            })
            .catch((e) => {
                console.log(e);
            })
    }

    useEffect(() => {
        getTestimonials();
    }, [])

    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                // className='Slider_top_custom_arrows-next'
                style={{ ...style, display: "none", background: "red", height: "80px", width: "80px" }}
                onClick={onClick}
            />
        );
    }


    function SamplePrevArrow(props) {
        const { style, onClick } = props;
        return (
            <div
                // className="Slider_top_custom_arrows"
                style={{ ...style, display: "none", background: "green", height: "80px", width: "80px" }}
                onClick={onClick}
            />
        );
    }

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,

        appendDots: dots => (
            <div
                style={{
                    // backgroundColor: "black",
                    borderRadius: "10px",
                    color: "white",
                    padding: "10px"
                }}
            >
                <ul style={{ margin: "0px" }}> <span style={{ color: "white" }}>{dots}</span> </ul>
            </div>
        ),
    };
    return (
        <div >
            <Slider {...settings} className="testizaam">
                {testimonial?.map((item, index) => {
                    return (
                        <div key={index} className="testimonails-item">
                            <div className="testimonails-header-wrapper">
                                <ul>
                                    <li>
                                        <img className='img-testimonazzzzzzzz' src={item?.media_url} alt="" />
                                    </li>
                                    <li>
                                        <div className="testimonails-name-wrapper">
                                            <h6>{item?.name}</h6>
                                            <span>
                                                {item?.designation}
                                            </span>
                                        </div>
                                    </li>
                                    <li>
                                        <img src={homequote} alt="" />
                                    </li>
                                </ul>
                            </div>
                            <div className="testimonails-content-wrapper">
                                <p className='dngrslimit_homez'>
                                   {item.feedback}
                                </p>
                            </div>
                        </div>
                    )
                })}

            </Slider>
        </div>
    )
}

export default TestimonialItems;