import React, { useEffect } from 'react';
import Header from '../../ReuseableComponents/Header';
import Footer from '../../ReuseableComponents/Footer';
import HomeContent from './HomeContent';
import '../../Assests/Css/style.css';
import { useDispatch, useSelector } from 'react-redux';
import { InfoAfterLogin } from '../../Redux/Auth/Auth_Action';

const HomePage = () => {
    const dispatch = useDispatch();
    const selector = useSelector((state) => state?.Auth);
    const InfoAfterLoginCheck = () => {
        dispatch(InfoAfterLogin(selector?.token))
            .then((res) => {
                // console.log('res from home js', res);
            })
            .catch((error) => {
                // console.log('error from home page', error);
                dispatch({ type: "DEL_TOKEN" });
            })
    }

    useEffect(() => {
        if (selector?.token) {
            InfoAfterLoginCheck();
        }
    }, []);

    return (
        <div className='HomePage'>
            <Header />
            <HomeContent />
            <Footer />
        </div>
    )
}

export default HomePage;