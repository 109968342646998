import React, { Component } from "react";
import classNames from "classnames";
import { Button, Typography, Divider, Paper } from "@material-ui/core";
import IframeApp from "../IframeApp";
//  import SDKApp from "../SDKApp"
import "./index.css";

class Home extends Component {
	constructor(props) {
		super(props);

		this.state = {
			iframeapp: false,
			// sdkapp: false,
			output: [],
		};
	}
	resetStates = () => {
		this.setState({ iframeapp: false, sdkapp: false, output: [] });
	};

	buttonHandler = (e) => {
		e.preventDefault();
		this.resetStates();

		switch (e.target.parentNode.id) {
			case "iframe":
				this.setState({ iframeapp: true });
				break;
			// case 'sdk':
			//     this.setState({sdkapp: true});
			//     break;
			default:
				console.log(".");
		}
	};

	backFunctionHandler = () => {
		this.resetStates();
	};

	clearOutput = () => {
		this.setState({ output: [] });
	};

	outputHandler = (message, clear = false) => {
		if (clear) {
			this.clearOutput();
		}
		message && this.setState({ output: [...this.state.output, message] });
	};

	render() {
		return (
			<div className="App">
				<div id="page-container" className="full-bleed" role="main">
					<div id="main-body-container">
						<div className="rightSection">
							<IframeApp
								backHandler={this.backFunctionHandler}
								outputHandler={this.outputHandler}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Home;
