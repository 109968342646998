import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { headerlogo } from '../MediaConstants/Index';
import PersonIcon from '@mui/icons-material/Person';

import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { GetSiteSettingsApi } from '../Redux/Auth/Auth_Action';
import icecreamlogo from "../../src/Assests/Images/icecreamlogo.png"

import SwipHeader from './SwipHeader';
import SearchIcon from '@mui/icons-material/Search';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import logo from '../Assests/Images/ondemand_logo.png';
import '../Assests/Css/header.css';

const Header = () => {

    const pathname = window.location.pathname;
    const getUserProfileRedux = useSelector((state) => state?.Auth);
    // const state = useSelector((state) => state.Auth);
    const dispatch = useDispatch();
    const [siteData, setSiteData] = useState(null);
    let navigate = useNavigate();
    let currentUrl = window.location.href.split("/");

    const getSiteSetting = () => {
        dispatch(GetSiteSettingsApi())
            .then((res) => {
                setSiteData(res?.data?.response?.data[0])
            })
            .catch((e) => {
                console.log(e);
            })
    }

    useEffect(() => {
        getSiteSetting();
    }, [])

    // console.log(getUserProfileRedux, 'pzzzzzzzzzzzzzzzzzzzzzzzzz')

    let headerLinks = [
        { name: "Home", link: "/", },
        { name: "About", link: "/about", },
        { name: "Menu", link: "/menu", },
        { name: "Gallery", link: "/gallery", },
        { name: "Contact Us", link: "/contact", },
        { name: "Truck Cleanliness", link: "/truck", },
    ];

    // let social_links = [
    //     { img_1: twitter },
    //     { img_1: fb },
    //     { img_1: insta },
    //     { img_1: youtube },
    // ]

    // social_links : social_links ,

    let headerLinks_props = { headerLinks: headerLinks, siteData: siteData }


    return (
        <div className='header_comp'>
            <header className='header-full' id="header">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-2">
                            <div className="logo-wrapper">
                                <Link to="/">
                                    <img src={siteData?.media_url} className="img-fluid ftr-logoooooooo" alt="" />
                                </Link>
                            </div>
                        </div>
                        <div className="col-7">
                            <div className="navbar navbar-wrapper">
                                <ul>
                                    <li className="nav-item">
                                        <Link to="/" className={pathname === "/" ? "navlink brdr_actv_linezz" : "navlink "}>Home</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/about" className={pathname === "/about" ? "navlink brdr_actv_linezz" : "navlink "}>About</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/menu" className={pathname === "/menu" ? "navlink brdr_actv_linezz" : "navlink "}>Menu</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/gallery" className={pathname === "/gallery" ? "navlink brdr_actv_linezz" : "navlink "}>Gallery</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/contact" className={pathname === "/contact" ? "navlink brdr_actv_linezz" : "navlink "}>Contact Us</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/truck" className={pathname === "/truck" ? "navlink brdr_actv_linezz" : "navlink "}>Truck Cleanliness</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/profile" className="navlink">
                                            {(getUserProfileRedux.token) ?
                                                <img className='profile_image_headers' src={getUserProfileRedux?.user?.profile_picture} alt="profile picture"></img>
                                                :
                                                <PersonIcon />
                                            }
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="header-btn">
                                <Link to="/bookevent" className="btn">Book Event Instantly</Link>
                                {/* <Link to="/ondemand" className="btn demand">On demand</Link> */}
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            {/* responsive header starts here */}
            <div className='header-small'>
                <div className='bg-red-hdr'>
                    <div className='header-small'>
                        <SwipHeader headerLinks={headerLinks_props} />
                    </div>
                    <div className='d-flex flx_profile_img'>
                        <div>
                            <Link to="/">
                                <img onClick={() => { navigate('/') }} className=' img_respon py-2 px-0' src={icecreamlogo} alt='logo' />
                                {/* {siteData?.media_url} */}
                            </Link>
                            {/* {getUserProfileRedux?.} */}
                            <Link to="/profile" className="navlink">
                                {(getUserProfileRedux.token) ?
                                    <img className='profile_image_headers' src={getUserProfileRedux?.user?.profile_picture} alt="profile picture"></img>
                                    :
                                    <PersonIcon className='clr_balluu' />
                                }
                            </Link>
                        </div>

                        <div className=''>
                            <Link to="/bookevent" className="btn bt-book-instant-mob">Book Event Instantly</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header;