import React, { useState } from "react";
import { authlogin, authloginlogo } from '../../MediaConstants/Index';
import '../../Assests/Css/login.css';
import { toast } from 'react-toastify';
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { SignupApi } from "../../Redux/Auth/Auth_Action";

const Signup = () => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [state, setState] = useState({
        f_name: "", l_name: "", email: "", phone: "", password: "", cpassword: "",
    });
    const [agree, setAgree] = useState(false);

    const InputVal = (e) => {
        let { name, value } = e.target;
        setState({ ...state, [name]: value });
    }

    const SubmitData = (e) => {
        e.preventDefault();
        if (!agree) {
            toast.error("Agree to our terms and conditions");
        }
        else if (state.password !== state.cpassword) {
            toast.error("password and confirm password not matched");
        }
        else if (state.password.length < 8) {
            toast.error("password must be 8 character limit");
        }
        else {
            let SignupData = {
                first_name: state.f_name,
                last_name: state.l_name,
                email: state.email,
                password: state.password,
                confirm_password: state.cpassword,
                phone_no: state.phone,
            }
            dispatch(SignupApi(SignupData))
                .then((res) => {
                    // console.log(res);
                    setTimeout(() => {
                        navigate('/login');
                    }, 7000)
                })
                .catch((e) => {
                    console.log(e);
                })
        }
    }

    return (
        <section className="signinSec p-0">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-6 p-0">
                        <div className="signInBox">
                            <div className="title">
                                <h2>Sign Up</h2>
                                <h5>Check out our <Link className="menu_txtx" to="/menu">menu</Link> </h5>
                            </div>
                            {/* <div className="taglines pb-4">
                                <h4>One Stop Solution For<br />
                                    All Your Community Needs
                                </h4>
                                <p>Not a Member ?<Link to='/login'>Sign In</Link></p>
                            </div> */}
                            <form onSubmit={SubmitData} method="POST">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input
                                                name="f_name"
                                                value={state.f_name || ""}
                                                onChange={InputVal}
                                                required
                                                type="text" className="form-control" placeholder="First Name" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input
                                                name="l_name"
                                                value={state.l_name || ""}
                                                onChange={InputVal}
                                                required
                                                type="text" className="form-control" placeholder="Last Name" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input
                                                name="email"
                                                value={state.email || ""}
                                                onChange={InputVal}
                                                required
                                                type="email" className="form-control" placeholder="Email" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input
                                                name="phone"
                                                value={state.phone || ""}
                                                onChange={InputVal}
                                                required
                                                type="tel" className="form-control" placeholder="Phone Number" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input
                                                name="password"
                                                value={state.password || ""}
                                                onChange={InputVal}
                                                required
                                                type="password" className="form-control" placeholder="Password" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input
                                                name="cpassword"
                                                value={state.cpassword || ""}
                                                onChange={InputVal}
                                                required
                                                type="password" className="form-control" placeholder="Confirm password" />
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="privacyDv">
                                            <p><span><input
                                                checked={agree}
                                                onChange={(e) => { setAgree(e.target.checked) }} className="mr-1" type="checkbox" /></span>By Creating Account you are agree to our <Link to="/termsandcondition">Terms & conditions</Link>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <button type="submit">Sign Up</button>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 p-0">
                        <div className="img-Dv">
                            <div className="imgBox">
                                <figure><img src={authlogin} alt="" /></figure>
                            </div>
                            <div className="LogoBox">
                                <figure><Link to="/"><img src={authloginlogo} alt="" /></Link></figure>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    )
}

export default Signup;