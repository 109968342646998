import React, { useEffect, useState } from 'react';
import { abouticon1, homeweare1, homeweare2, homeweareimg, truckico, truckico2, truckico3, truckico4 } from '../../MediaConstants/Index';
import Footer from '../../ReuseableComponents/Footer';
import Header from '../../ReuseableComponents/Header';
import HeaderBanner from '../../ReuseableComponents/HeaderBanner';
import '../../Assests/Css/truck.css';
import { useDispatch } from 'react-redux';
import { TruckCleanessApi } from '../../Redux/Auth/Auth_Action';

const TruckCleaness = () => {
    const [truckCleaness, setTruckCleaness] = useState();

    const dispatch = useDispatch();

    const GetAboutTopContent = () => {
        dispatch(TruckCleanessApi())
            .then((res) => {
                setTruckCleaness(res.data.response.data);
            })
            .catch((e) => {
                console.log(e);
            })
    }

    useEffect(() => {
        GetAboutTopContent();
        window.scroll(0,0);
    }, [])

    return (
        <div className='TruckPage'>
            <Header />
            <HeaderBanner name="Truck cleanliness" />
            {/* <!-- Contact Info Start Here --> */}
            <section className="who-we-are">
                <div className="container">
                    <div className="heading_wrapper">
                        {/* <h3>truck cleanliness</h3> */}
                        <h2>Here's What We Do To Keep You Healthy</h2>
                    </div>
                    {/* {truckCleaness?.map((item, index) => {
                        console.log(item, 'zzzzzzzzzzzzzzzzzzzzzzzzz');
                        if (item.type === "banner") {
                            if ((index + 1) % 2 === 0) {
                                return (
                                    <div key={index} className="row pb-5">
                                       <div className="col-lg-6">
                                            <div className="content-wrapper wow animate__animated animate__fadeInRight">
                                                <figure><img src={item.image_url} className="img-fluid" /></figure>
                                                <h2>{item.title}</h2>
                                                <div
                                                    dangerouslySetInnerHTML={{ __html: item.description }}
                                                ></div>
                                            </div>
                                        </div>
                                        <div className="col-lg-1"></div>
                                        <div className="col-lg-5">
                                            <div className="img-wrapper wow animate__animated animate__fadeInLeft">
                                                <figure>
                                                    <img src={item.cover_image_url}
                                                        className="img-fluid trk_imggg" alt="" />
                                                </figure>
                                            </div>
                                        </div>
                                    </div>
                                )
                            } else {
                                return (
                                    <div key={index} className="row pb-5">
                                        <div className="col-lg-5">
                                            <div className="img-wrapper wow animate__animated animate__fadeInLeft">
                                                <figure>
                                                    <img src={item.cover_image_url}
                                                        className="img-fluid trk_imggg" alt="" />
                                                </figure>
                                            </div>
                                        </div>
                                        <div className="col-lg-1"></div>
                                        <div className="col-lg-6">
                                            <div className="content-wrapper wow animate__animated animate__fadeInRight">
                                                <figure><img src={item.image_url} className="img-fluid " /></figure>
                                                <h2>{item.title}</h2>
                                                <div
                                                    dangerouslySetInnerHTML={{ __html: item.description }}
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        }
                    })} */}
                </div>
                <img src={homeweare2} className="we1" alt="" />
                <img src={homeweare1} className="we2" alt="" />
            </section>
            {/* <!-- Truck ends here -->

                    <!-- Truck Points Sec Start Here --> */}
            <section className="truck_point">
                <div className="container">
                    <div className="row">
                        {truckCleaness?.map((item, index) => {
                            if (item.type === "sub_banner") {
                                return (
                                    <div key={index + 100} className="col-md-6">
                                        <div className="text_with_icon">
                                            <div className="icon_box">
                                                <figure><img src={item.image_url} className="img-fluid" /></figure>
                                            </div>
                                            <div className="text">
                                                <h2>{item.title}</h2>
                                                <div dangerouslySetInnerHTML={{ __html: item.description }}>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        })}
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default TruckCleaness;