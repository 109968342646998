import React from 'react';
import {
    BrowserRouter,
    Routes,
    Route,
    Navigate
} from "react-router-dom";
import About from '../Pages/About/About';
import Contact from '../Pages/Contact/Contact';
import Gallery from '../Pages/Gallery/Gallery';
import HomePage from '../Pages/HomePage/Home';
import Menu from '../Pages/Menu/Menu';
import OnDemand from '../Pages/OnDemand/OnDemand';
import PrivacyPolicy from '../Pages/PolicyPages/PrivacyPolicy';
import TermsAndCondition from '../Pages/PolicyPages/TermsAndCondition';
import TruckCleaness from '../Pages/TruckCleaness/TruckCleaness';
import Login from '../Pages/Auth/Login';
import Signup from '../Pages/Auth/Signup';
import Faqs from '../Pages/Faqs/Faqs';
import EnterEmail from '../Pages/Auth/EnterEmail';
import Verification from '../Pages/Auth/Verification';
import NewPass from '../Pages/Auth/NewPass';
import Fill from '../Pages/Fill/Fill';
import Congrats from '../Pages/Congrats/Congrats';
import BookEvent from '../Pages/BookEvent/BookEvent';
import SideProfileTab from '../Pages/ProfilePages/SideProfileTab';
import ViewProfile from '../Pages/ProfilePages/ViewProfile';
import ThankYou from '../Pages/Thankyou/Thankyou';
import RequireAuth from './RequireAuth';
import FeedBack from '../Pages/FeedBack/FeedBack';


const AllRoutes = () => {
    return (
        <div className='AllRoutes'>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/menu" element={<Menu />} />
                    <Route path="/gallery" element={<Gallery />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/truck" element={<TruckCleaness />} />
                    <Route path="/ondemand" element={<OnDemand />} />
                    <Route path="/termsandcondition" element={<TermsAndCondition />} />
                    <Route path="/faq" element={<Faqs />} />
                    <Route path="/privacypolicy" element={<PrivacyPolicy />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/signup" element={<Signup />} />
                    <Route path="/verification" element={<Verification />} />
                    <Route path="/enteremail" element={<EnterEmail />} />
                    <Route path="/newpass" element={<NewPass />} />
                    <Route path="/fill" element={<Fill />} />
                    <Route path="/congrats" element={<Congrats />} />
                    <Route path="/bookevent" element={<BookEvent />} />
                    <Route path="/thankyou" element={<ThankYou />} />
                    <Route path="/feedback" element={<FeedBack />} />
                    <Route path="*" element={<HomePage />} />
                    {/* <Route path="/" element={< />} /> */}
                    <Route path="/profile"
                        element={
                            <RequireAuth>
                                <ViewProfile />
                            </RequireAuth>
                        } />
                </Routes>
            </BrowserRouter>
        </div>
    )
}

export default AllRoutes;