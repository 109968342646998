import React, { useEffect, useState } from "react";
// import { } from '../../MediaConstants/Index';
import Footer from '../../ReuseableComponents/Footer';
import Header from '../../ReuseableComponents/Header';
import HeaderBanner from '../../ReuseableComponents/HeaderBanner';
import '../../Assests/Css/privacy.css';
import { useDispatch } from 'react-redux';
import { staticPagesApi } from "../../Redux/Auth/Auth_Action";

const TermsAndCondition = () => {

    const [policy, setPolicy] = useState();
    const dispatch = useDispatch();
    const getPolicyData = () => {
        dispatch(staticPagesApi("terms-and-conditions"))
            .then((res) => {
                // console.log(res.data.response.data[0]);
                setPolicy(res.data.response.data[0]);
            })
            .catch((e) => {
                console.log(e);
            })
    }

    useEffect(() => {
        getPolicyData();
        window.scroll(0,0);
    }, [])

    return (
        <div className="TermsAndCondition_Page">
            <Header />
            <HeaderBanner name="Terms And Conditions" />
            <section className="privacy_sec">
                <div className="container">
                    <div className="heading_wrapper">
                        <h2>{policy?.page_title}</h2>
                    </div>
                    <div className="content_wrapper text-left" dangerouslySetInnerHTML={{ __html: policy?.content }}>

                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default TermsAndCondition;