import React, { useEffect, useState } from 'react';
import { gal1, gal2, gal3, gal4, gal5, gal6, homeweare1, homeweare2 } from '../../MediaConstants/Index';
import Footer from '../../ReuseableComponents/Footer';
import Header from '../../ReuseableComponents/Header';
import HeaderBanner from '../../ReuseableComponents/HeaderBanner';
import '../../Assests/Css/gallery.css';
import { getGalleryApi, getGalleryPaginateApi } from '../../Redux/Auth/Auth_Action';
import { useDispatch } from 'react-redux';

const Gallery = () => {

    const [menu, setMenu] = useState();
    const [links, setLinks] = useState();
    const dispatch = useDispatch();

    const getMenuItems = () => {
        dispatch(getGalleryApi())
            .then((res) => {
                // console.log("................",res.data.response);
                // let sortzVal = res?.data?.response?.data?.data?.sort(function(a,b){
                //     return a?.key - b?.key;
                // });
                // console.log(sortzVal,'....................');
                // setMenu(sortzVal);
                setMenu(res?.data?.response?.data?.data);
                setLinks(res?.data?.response?.data?.links)
            })
            .catch((e) => {
                console.log('rezzzzz', e);
            })
    }

    useEffect(() => {
        getMenuItems();
        window.scroll(0,0);
    }, []);

    const MenuPagination = (e) => {
        dispatch(getGalleryPaginateApi(e))
            .then((res) => {
                // let sortzVal = res?.data?.response?.data?.data?.sort(function(a,b){
                //     return a?.key - b?.key;
                // });
                // console.log(sortzVal,'....................');
                // setMenu(sortzVal);
                setMenu(res?.data?.response?.data?.data);
                setLinks(res?.data?.response?.data?.links)
            })
            .catch((e) => {
                console.log(e);
            })
        // console.log(';zzzasdasd',e);
       
    }

    return (
        <div className='GalleryPage'>
            <Header />
            <HeaderBanner name="Gallery" />
            <section className='gallery_sec'>
                <div className="container">
                    <div className="heading_wrapper">
                        <h3>Gallery</h3>
                        <h2>we Capture moments</h2>
                    </div>
                    <div className="row">
                        {menu?.map((item, index) => {
                            return (
                                <div key={index} className="col-lg-4 col-md-6">

                                    <div className="gallery_box">
                                        <figure>
                                            <img src={item.media_url} className="img-fluid w-100 gal-imgzzzz" />
                                        </figure>
                                    </div>

                                </div>
                            )
                        })}
                    </div>
                    <div className='text-right'>
                        {links?.map((item, index) => {
                            if (item.label === "&laquo; Previous") {
                                item.label = "Previous";
                                return (
                                    <button key={index + 100}
                                        onClick={() => { MenuPagination(item.url) }}
                                        disabled={(item.url === null ? true : false)}
                                        className='btn btn-light bt-pagination-all'>
                                        {item.label}
                                    </button>
                                )
                            } else if (item.label === "Next &raquo;") {
                                item.label = "Next";
                                return (
                                    <button key={index + 100}
                                        onClick={() => { MenuPagination(item.url) }}
                                        disabled={(item.url === null ? true : false)}
                                        className='btn btn-light bt-pagination-all'>
                                        {item.label}
                                    </button>
                                )
                            } else {
                                return (
                                    <button key={index + 100}
                                        onClick={() => { MenuPagination(item.url) }}
                                        disabled={(item.url === null ? true : false)}
                                        className='btn btn-light bt-pagination-all'>
                                        {item.label}
                                    </button>
                                )
                            }
                        })}
                    </div>
                    {/* <ul className="pagination">
                        <li><a href="#" className="active">1</a></li>
                        <li><a href="#">2</a></li>
                        <li><a href="#">3</a></li>
                        <li><a href="#" className="more">...</a></li>
                        <li><a href="#">4</a></li>
                        <li><a href="#" className="btn">Next</a></li>
                    </ul> */}
                </div>
                <img src={homeweare2} className="we1" alt="" />
                <img src={homeweare1} className="we2" alt="" />
            </section>
            <Footer />
        </div>
    )
}

export default Gallery;