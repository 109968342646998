import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { feedback_ava } from "../../MediaConstants/Index";
import { PostTestimonialApi } from "../../Redux/Auth/Auth_Action";
import Footer from "../../ReuseableComponents/Footer";
import Header from "../../ReuseableComponents/Header";
import HeaderBanner from "../../ReuseableComponents/HeaderBanner";

const FeedBack = () => {
    let navigate = useNavigate();
    const ReduxState = useSelector((state) => state.Auth);
    const dispatch = useDispatch();
    const [state, setState] = useState({
        name: "",
        designation: "",
        description: "",
    });
    const [image, setImage] = useState();

    const [uploadImage, setUploadImage] = useState();
    const [updateImage, setUpdateImage] = useState(feedback_ava);

    const InputVal = (e) => {
        let { name, value } = e.target;
        setState({ ...state, [name]: value })
    }

    const UploadImage = (event) => {
        if ((event.target.files[0].size / 1000000) <= 5) {
            // console.log("workssszzzzzzzzzzzzzzzzzzzzzzzzzzz", event);
            setUploadImage(event.target.files[0]);
            setUpdateImage(URL.createObjectURL(event.target.files[0]))
            // props.updateImage({ ...updateProfile, prof_img: event.target.files[0] })
            // console.log('sucess', (event.target.files[0].size / 1000000));
            // console.log(selectedImage,'selectedImageselectedImage')
        } else {
            toast.error("file size is greater than 5 mb");
        }
    }

    const SubmitData = (e) => {
        e.preventDefault();
        if (!uploadImage) {
            toast.error("Please upload image");
        } else {


            let data = new FormData();    //formdata object

            data.append('name', state.name);   //append the values with key, value pair
            data.append('designation', state.designation);
            data.append('feedback', state.description);
            data.append('image', uploadImage);

            // let data = {
            //     name: state.name,
            //     designation: state.designation,
            //     feedback: state.description,
            //     image: uploadImage,
            // }

            dispatch(PostTestimonialApi(data, ReduxState?.token))
                .then((res) => {
                    // console.log("ress", res);
                    toast.success("Thanks for submitting feedback");
                    setTimeout(() => {
                        navigate('/');
                    }, 2500);
                })
                .catch((e) => {
                    console.log("ress", e);
                })
        }

    }

    // console.log('uploadImage uploadImage', uploadImage);
    // console.log('updateImage updateImage', updateImage);
    // console.log('state state state', state);

    return (
        <div className="FeedBack_Page">
            {/* <Header /> */}
            <HeaderBanner name="Feed Back" />
            <section className="feedback_page">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="mt-3 mb-3">Give feed back to let us know about our services</h1>
                            <form method="POST" onSubmit={SubmitData}>
                                <div className="row mb-5">
                                    <div className="col-lg-4 pos-rel my-2 text-center">
                                        <img src={updateImage} className="previewImageDiv" />
                                        <input onChange={UploadImage} className="uploadImg" type='file' />
                                    </div>
                                    <div className="col-lg-8">
                                        <div className="row">
                                            <div className="col-lg-6 my-2">
                                                <input
                                                    required
                                                    value={state.name}
                                                    name="name"
                                                    onChange={InputVal}
                                                    placeholder="Enter Your Name" className="form-control" />
                                            </div>
                                            <div className="col-lg-6 my-2">
                                                <input
                                                    required
                                                    value={state.designation}
                                                    name="designation"
                                                    onChange={InputVal}
                                                    placeholder="Enter Your Designation" className="form-control" />
                                            </div>
                                            <div className=" col-lg-12 my-2">
                                                <textarea
                                                    required
                                                    value={state.description}
                                                    name="description"
                                                    onChange={InputVal}
                                                    rows={5} placeholder="Enter Your Feedback" className="form-control txt-area-fdbck" ></textarea>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 text-right my-2">
                                        <button type="submit" className="btn btn-light">Send</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default FeedBack;