import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PromoCodeApi } from '../../Redux/Auth/Auth_Action';

const HomeBannerContent2 = () => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [state, setState] = useState({
        name: "",
        email: "",
        number: "",
        address: "",
    });

    const InputVal = (e) => {
        let { name, value } = e.target;
        setState({ ...state, [name]: value });
    }

    const SubmitData = (e) => {
        e.preventDefault();
        let data = {
            name: state.name,
            email: state.email,
            number: state.number,
            address: state.address,
        }
        dispatch(PromoCodeApi(data))
            .then((res) => {
                // console.log(res);
                setTimeout(() => {
                    navigate('/congrats');
                }, 1000);
            })
            .catch((err) => {
                let message = typeof err.response !== "undefined" ? err.response.data.message : err.message;
                toast.error(message);
                console.log(err);
            })
    }

    return (
        <div className='HomeBannerContent1'>
            <div className="banner-form-wrapper form_two">
                <div className="banner-form-content-wrapper wow animate__animated animate__fadeInDown">
                    {/* <h2>Fill your Information</h2> */}
                    <h5> <span className='Fill_In_Your_Information'>Fill In Your Information</span>   <br /> For A Free Cone On Your Next On Demand Order</h5>
                </div>
                <form onSubmit={SubmitData}>
                    <div className="form-group">
                        <input name="name" value={state.name || ""} onChange={InputVal} type="text" placeholder="NAME" className="form-control" />
                    </div>
                    <div className="form-group">
                        <input name="email" value={state.email || ""} onChange={InputVal} type="email" placeholder="EMAIL" className="form-control" />
                    </div>
                    <div className="form-group">
                        <input name="number" value={state.number || ""} onChange={InputVal} type="tel" placeholder="NUMBER" className="form-control" />
                    </div>
                    <div className="form-group">
                        <input name="address" value={state.address || ""} onChange={InputVal} type="text" placeholder="ADDRESS" className="form-control" />
                    </div>
                    <div className="form-group">
                        <button type="submit" className="btn form-control py-0">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default HomeBannerContent2;