import React, { useState } from "react";
import { authlogin, authloginlogo, authloginnew, ondemand_logo } from '../../MediaConstants/Index';
import '../../Assests/Css/login.css';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { LoginApi } from "../../Redux/Auth/Auth_Action";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

const Login = () => {

    let navigate = useNavigate();
    const dispatch = useDispatch();
    const reduxState = useSelector((state) => state?.Auth);
    const [state, setState] = useState({
        email: (reduxState?.rememberMe) ? reduxState?.user?.email : "",
        password: (reduxState?.rememberMe) ? reduxState?.password : "",
    });
    const [checked, setChecked] = useState(true);
    const [agree, setAgree] = useState(false);
    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    const InputVal = (e) => {
        let { name, value } = e.target;
        setState({ ...state, [name]: value });
    }

    const SubmitData = (e) => {
        e.preventDefault();
        let infoData = {
            email: state.email,
            password: state.password,
        }
        dispatch(LoginApi(infoData, checked))
            .then((res) => {
                navigate('/profile');
            })
            .catch((e) => {
                // toast.error('invalid credientials');
                console.log(e);
            })
    }

    return (
        <section className="signinSec p-0">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-6 p-0">
                        <div className="signInBox">
                            <div className="title">
                                <h2>Sign In</h2>
                            </div>
                            <div className="taglines pb-4">
                                {/* <h4>One Stop Solution For<br />
                                    All Your Community Needs
                                </h4> */}
                                <p>Not a Member ?<Link to="/signup">Sign Up</Link></p>
                                <h5>Check out our <Link className="menu_txtx" to="/menu">menu</Link> </h5>
                            </div>
                            <form onSubmit={SubmitData}
                                methode="POST">
                                <div className="form-group">
                                    <input
                                        value={state.email}
                                        name="email"
                                        required
                                        onChange={InputVal}
                                        type="email" className="form-control" placeholder="Email" />
                                </div>
                                <div className="form-group">
                                    <input
                                        value={state.password}
                                        name="password"
                                        required
                                        onChange={InputVal}
                                        type="password" className="form-control" placeholder="Password" />
                                </div>
                                <div className="actionsLink py-4">
                                    <div className="remeberDv">
                                        <div className="form-group">
                                            <input
                                                onChange={handleChange}
                                                checked={checked}
                                                className="mr-1"
                                                type="checkbox" id="remember" />
                                            <label htmlFor="remember">Remember Me</label>
                                        </div>
                                    </div>
                                    <div className="forgotDv">
                                        <Link to="/enteremail">Forgot Password ?</Link>
                                    </div>
                                </div>
                                <button type="submit">Sign in</button>
                                <button onClick={()=>{navigate('/bookevent')}} className="btn btn_signin" type="button">Book Event Instantly</button>
                            </form>
                            {/* <div className="privacyDv">
                                <p><span><input onChange={setAgree} checked={agree} className="mr-1" type="checkbox" /></span>By Creating Account you are agree to our <Link to="/termsandcondition">Terms & conditions</Link>
                                </p>
                            </div> */}
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 p-0">
                        <div className="img-Dv">
                            <div className="imgBox">
                                <figure><img src={authlogin} alt="" /></figure>
                            </div>
                            <div className="LogoBox">
                                <figure><Link to="/"><img src={authloginnew} width={200} alt="" /></Link></figure>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Login;