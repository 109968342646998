import React, { Component, useEffect, useState } from "react";
import "./index.css";
import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { payApi } from "../Redux/Auth/Auth_Action";
import { toast } from "react-toastify";

const styles = {
	body: {
		fontFamily: "Roboto, Open Sans, sans-serif",
		fontSize: "16px",
	},
	input: {
		fontSize: "13px",
		border: "1px solid #aaa",
		height: "50px",
		borderRadius: "10px",
		padding: "8px",
	},
	img: {
		right: "10px !important",
		top: "10px !important",
	},
};

const IframeApp = (props) => {
	const elements = window.clover.elements();
	const Reduxstate = useSelector((state) => state.Auth);
	const dispatch = useDispatch();
	let navigate = useNavigate();
	console.log(Reduxstate, "ksdkaasdaksd");
	const [state, setState] = useState({
		token: null,
		output: [],
		showUserInfo: false,
		customerId: "",
		user: {
			firstName: "John",
			lastName: "Doe",
			email: "John.Doe@corona.com",
		},
		card_expiry: "04/2022",
		card: {
			number: "4005562231212123",
			brand: "VISA",
			cvv: "123",
			exp_month: "04",
			exp_year: "2022",
		},
	});

	let cardNumber = elements.create("CARD_NUMBER", styles);
	let cardDate = elements.create("CARD_DATE", styles);
	let cardCvv = elements.create("CARD_CVV", styles);
	let cardPostalCode = elements.create("CARD_POSTAL_CODE", styles);
	const cloverFooter = document.getElementsByClassName("clover-footer")[0];
	useEffect(() => {
		const displayError = document.getElementById("card-errors");
		cardNumber.mount("#card-number");
		cardDate.mount("#card-date");
		cardCvv.mount("#card-cvv");
		cardPostalCode.mount("#card-postal-code");

		if (cloverFooter) {
			cloverFooter.style.display = "none";
		}
	}, [cloverFooter]);

	const generateMask = (cardLast4) => {
		return cardLast4.padStart(16, "*");
	};

	const callCreateChargeAPI = async (response) => {
		const source = response.token;
		const card = response.card;
		props.outputHandler(
			`Charging Card '${generateMask(card.last4)}' for $25.00... ${source}`,
		);

		// const source = this.state.showUserInfo ? this.state.customerId : this.state.token;
		const data = JSON.stringify({ source: source });
		console.log(response, data, "Ahhsdhsdh");
		let orderData = {
			orderdata: {
				slug: Reduxstate.firstSaveData.e.slug,
				event_date: Reduxstate?.firstSaveData?.e?.event_date,
				start_from: Reduxstate?.firstSaveData?.e?.start_from,
				finish_by: Reduxstate?.firstSaveData?.e?.finish_by || "00:00:00",
				price: Reduxstate?.firstSaveData?.e?.final_price,
				estimated_guests: Reduxstate?.firstSaveData?.e?.estimated_guests,
				first_name: Reduxstate?.secondSaveData?.e?.first_name,
				last_name: Reduxstate?.secondSaveData?.e?.last_name,
				phone_no: Reduxstate?.secondSaveData?.e?.phone_no,
				email: Reduxstate?.secondSaveData?.e?.email,
				company_name: Reduxstate?.secondSaveData?.e?.company_name,
				address: Reduxstate?.secondSaveData?.e?.address,
				city: Reduxstate?.secondSaveData?.e?.city,
				description: Reduxstate?.secondSaveData?.e?.description,
				guest_of_honour: Reduxstate?.secondSaveData?.e?.guest_of_honour,
				allergies: Reduxstate?.secondSaveData?.e?.allergies,
				user_id: Reduxstate.user.id ? Reduxstate.user.id : 0,
				_token: response?.token,
				payment_type: "clover",
			},
		};

		// console.log("paaaaaaaaaaddddssssss", orderData);
		let UserToken = Reduxstate?.token;
		dispatch(payApi(orderData, UserToken))
			.then((res) => {
				// console.log("sucessfull event created", res);
				toast.success("Booking has been done successfully");
				setTimeout(() => {
					navigate("/thankyou");
				}, 2000);
			})
			.catch((e) => {
				console.log("not wokring zzzzzzzzzzzzzzzz", e);
			});
	};

	const handleFormSubmit = (event) => {
		event.preventDefault();
		props.outputHandler(null, true);

		const displayError = document.getElementById("card-errors");

		props.outputHandler("Genarating Token (Using Encrypted Pan) ...");

		window.clover
			.createToken()
			.then((result) => {
				if (result.errors) {
					Object.values(result.errors).forEach(function (value) {
						displayError.textContent = value;
					});
				} else {
					props.outputHandler(`Token Id is -> ${result.token}`);
				}
				return result;
			})
			.then((resp) => callCreateChargeAPI(resp))
			.catch((err) => console.log(err));
	};

	// useEffect(() => {
	// 	return () => {
	// 		const cloverFooter = document.getElementsByClassName("clover-footer")[0];
	// 		cloverFooter && cloverFooter.parentNode.removeChild(cloverFooter);
	// 	};
	// }, []);

	return (
		<div className="App" id="iframeapp">
			<div id="card-errors" role="alert" />
			<div className="flex justify-center mt-16">
				<form id="payment-form" noValidate autoComplete="off">
					<fieldset className="FormGroup">
						<div className="FormRow">
							<div id="card-number" className="field card-number" />
						</div>

						<div className="FormRow">
							<div id="card-date" className="field third-width" />
							<div id="card-cvv" className="field third-width" />
							<div id="card-postal-code" className="field third-width" />
						</div>
					</fieldset>
				</form>

				<Button
					type="button"
					variant="contained"
					size="large"
					onClick={() => props.backHandler()}
				>
					Back
				</Button>
				<Button
					variant="contained"
					color="primary"
					size="large"
					onClick={handleFormSubmit}
				>
					Pay ${Reduxstate?.firstSaveData?.e?.final_price}
				</Button>
			</div>
		</div>
	);
};

export default IframeApp;
