import React from 'react';
import { aboutinnerbg, aboutwatermark } from '../MediaConstants/Index';

const HeaderBanner = (props) => {
    return (
        <>
            {/* <!-- Universal Banner Start Here --> */}
            <section className="universal_banner">
                <div className="container-fluid p-0">
                    <div className="banner_img">
                        <figure><img src={aboutinnerbg} className="img-fluid" /></figure>
                    </div>
                    <div className="page-head">
                        <h2>{props.name}</h2>
                    </div>
                </div>
            </section>
            {/* <!-- Universal Banner End Here -->
            <!-- Watermark Image --> */}
            <div className="watermark">
                <figure><img src={aboutwatermark} className="img-fluid" /></figure>
            </div>
            {/* /* <!-- Watermark Image --> */}
        </>
    )
}

export default HeaderBanner;