import React, { useState } from 'react';
import Footer from '../../ReuseableComponents/Footer';
import Header from '../../ReuseableComponents/Header';
import MyBookings from './MyBookings';
import Security from './Security';
import ShowProfile from './ShowProfile';
import SideProfileTab from './SideProfileTab';

const ViewProfile = () => {
    const [changeTabs, setChangeTabs] = useState(1);
    // console.log('paaaaaaaaa', changeTabs);

    return (
        <div className='view_profile_page'>
            <div className='big_blue_div_profe'>
                <Header />
            </div>
            <section className="profile">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <SideProfileTab setChangeTabs={setChangeTabs} />
                        </div>
                        <div className="col-lg-9">
                            <div className="profile-tabs-content-wrapper">
                                <div className="tab-content" id="v-pills-tabContent">
                                    {changeTabs === 1 && <ShowProfile />}
                                    {changeTabs === 2 && <Security />}
                                    {changeTabs === 3 && <MyBookings />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default ViewProfile;