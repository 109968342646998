import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick/lib/slider";
import { homeapple, homegoogle } from "../../MediaConstants/Index";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const TopSliderHome = (props) => {
  const navigate = useNavigate("/");
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className="Slider_top_custom_arrows-next"
        // style={{ ...style, display: "block", background: "red", height: "80px", width: "80px" }}
        onClick={onClick}
      >
        <ArrowBackIosIcon />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { style, onClick } = props;
    return (
      <div
        className="Slider_top_custom_arrows"
        // style={{ ...style, display: "block", background: "green", height: "80px", width: "80px" }}
        onClick={onClick}
      >
        <ArrowForwardIosIcon />
      </div>
    );
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 1200,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    autoplay: true,
    autoplaySpeed: 6000,
    appendDots: (dots) => (
      <div
        style={{
          // backgroundColor: "black",
          borderRadius: "10px",
          color: "white",
          padding: "10px",
        }}
      >
        <ul style={{ margin: "0px" }}>
          {" "}
          <span style={{ color: "white" }}>{dots}</span>{" "}
        </ul>
      </div>
    ),
  };

  // "Helvetica Rounded LT Std" !important

  return (
    <div className="TopSliderHome">
      <Slider {...settings} className="slider_top_home">
        {/* wow animate__animated animate__fadeInLeft */}
        {/* text-center */}
        <div className="banner-content-wrapper pl-1">
          <h1 className="fnt_head_sliderz_top mb-0">ICE CREAM TRUCKS</h1>
          {/*classNmae=" red_font " */}
          <h2 className="fnt_head_sliderz_top">
            {" "}
            NOW ON DEMAND <br />
            {/* IN BROOKLYN */}
          </h2>
          {/* <p className='pr-4'>Whether you want ice at your wedding, birthday party, workplace, or school fundraiser, our new and cool ice cream truck can be available on-demand</p> */}

          <button
            onClick={() => {
              props.setChangeHomeBanner(2);
            }}
            className="btn free_topping "
          >
            Free Cone
          </button>
          <Link to="/ondemand" className="btn ml-2 ">
            Learn More{" "}
          </Link>
        </div>
        <div className="banner-content-wrapper  pl-1">
          <h1 className="fnt_head_sliderz_top_two ">Cones App </h1>
          <h2 className="fnt_head_sliderz_top_two ">DOWNLOAD NOW </h2>
          {/* <h2 className='red_font'>Available Now</h2> */}
          {/* <p className='pr-4'>What can be more exciting than ordering your favorite ice cream truck via a mobile application?</p> */}
          {/* <p className='pr-4'>Whether you want ice cream at your wedding, birthday party, workplace, or school fundraiser, we got you covered.</p> */}
          <div>
            {/* <button onClick={() => { navigate('/bookevent') }} className="btn bt-cus-icecrm mb-4">ICE CREAM AT YOUR DOOR? YES PLEASE.</button> */}
          </div>
          {/* justify-center */}
          <div className="d-flex flx-center-home_top mt-3">
            <a href="https://apps.apple.com/us/app/cones-app/id6443475272">
              <img
                src={homeapple}
                className="img-fluid mr-2 img_res_msall"
                alt=""
              />
            </a>
            {/* <a href="https://play.google.com/store/apps/details?id=com.mistersofteeondemand&pli=1"> */}
            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.conesapp">
              <img
                src={homegoogle}
                className="img-fluid img_res_msall"
                alt=""
              />
            </a>
          </div>
          {/* <Link to="/ondemand" className="btn">On Demand</Link>
                    <button onClick={() => { props.setChangeHomeBanner(2) }} className="btn free_topping ml-2">Get Free Topping</button> */}
        </div>
      </Slider>
    </div>
  );
};

export default TopSliderHome;
