import React, { useEffect, useState } from 'react';
import { bookeveevent, bookevepcard1, bookevepcard2, bookevepcard3, bookevepcard4, homeconnect, homeicecrmsndwchch, homeweare1, homeweare2 } from '../../MediaConstants/Index';
import Footer from '../../ReuseableComponents/Footer';
import Header from '../../ReuseableComponents/Header';
import HeaderBanner from '../../ReuseableComponents/HeaderBanner';
import '../../Assests/Css/book-event.css';
import EventOne from './EventOne';
import EventTwo from './EventTwo';
import EventThree from './EventThree';
import Testimonials from '../../ReuseableComponents/Testimonials';
import TestimonialItems from '../HomePage/TestimonialItems';
import { useLocation } from 'react-router-dom';

const BookEvent = () => {
    const location = useLocation();
    // console.log('location', location?.state?.passData);
    let CheckData = location?.state?.passData;

    const [stepForm, setStepForm] = useState(1);
    const changeTab = (e) => {
        setStepForm(e);
        // console.log("working here", stepForm);
    }

    useEffect(() => {
        if (CheckData) {
            setStepForm(2);
        }
    }, [])

    useEffect(()=>{
        window.scroll(0,0);
    },[])

    return (
        <div className='BookEvent_Page'>
            <Header />
            <HeaderBanner name="Book Event" />
            <section className="book-event">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 mt-bhotzada">
                            <div className="event_img">
                                <figure><img src={bookeveevent} className="img-fluid" /></figure>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="wizard">
                                {(stepForm === 1) ? <EventOne changeTab={changeTab} /> : null}
                                {(stepForm === 2) ? <EventTwo changeTab={changeTab} /> : null}
                                {(stepForm === 3) ? <EventThree changeTab={changeTab} /> : null}
                            </div>
                        </div>
                    </div>
                </div>
                <img src={homeweare2} className="we1" alt="" />
                <img src={homeweare1} className="we2" alt="" />
            </section>

            {/* testimonials sectn strts*/}
            <div className='HomeContent'>
                <section className="testimonials pt-5 mt-5">
                    <img src={homeconnect} className="connec" alt="" />
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="content-wrapper wow animate__animated animate__fadeInDown">
                                    <h3>Their Words. Our Motivation.</h3>
                                    {/* <h2>Testimonials</h2> */}
                                    <p>
                                        Here’s what our customers say about us.
                                       </p>
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="testimonails-wrapper">
                                    {/* 3 time same */}
                                    <TestimonialItems />
                                </div>
                                <div className="img-wrapper">
                                    <img src={homeicecrmsndwchch} className="sandwish" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            {/* testimonials sectn ends*/}

            {/* gallery section strts*/}

            {/* gallery section ends*/}

            <Footer />
        </div>
    )
}

export default BookEvent;