

import React, { useState } from "react";
import { bookevepcard1, bookevepcard2, bookevepcard3, bookevepcard4 } from "../../MediaConstants/Index";
import StripeForm from "../../ReuseableComponents/StripeForm";
import Clover from "../../ReuseableComponents/Clover";
import { useDispatch, useSelector } from 'react-redux';
import Clovercompo from "../../ReuseableComponents/Clovercompo";
import Cloverall from "../../ReuseableComponents/Cloverall";

const EventThree = (props) => {
    const reduxState = useSelector((state) => state?.Auth);
    // const [cardHolderName, setCardHolderName] = useState("");
    // console.log(reduxState);

    const handleClickOpen = (e) => {
        // console.log(e);
    };

    return (
        <div className="EventTwo_Page">
            {/* <h1>THREEEEEEE</h1> */}
            <div className="wizard-inner">
                <div className="maindiv_lambiline">
                    <div className="lambi_line">
                    </div>
                    <div className="flx_stepsboxes">
                        <div>
                            <p className="bx-noactive">1</p>
                            <p className="step1">Step 1</p>
                        </div>
                        <div>
                            <p className="bx-noactive">2</p>
                            <p className="step1">Step 2</p>
                        </div>
                        <div>
                            <p className="bx-active">3</p>
                            <p className="step1">Step 3</p>
                        </div>
                    </div>
                </div>

                <div className="hidden-tab"></div>
            </div>
            <div className="login-box">
                <div>
                    <div className="tab-pane active" >
                        <div className="banner-form-wrapper scnd-step">
                            <hr />
                            <div className="banner-form-content-wrapper wow animate__animated animate__fadeInDown">
                                <h4 className="mb-4">Order Details</h4>
                                {/* <p className="bold_txt_highlight">the payment charged will be only $150 and the remaining amount will be charged at the event,</p> */}

                           
                            </div>
                            {/* <div className="form-group">
                                <label>Name on Card</label>
                                <input type="text" className="form-control" />
                            </div> */}

                            {/* <ul className="payments_cards">
                                <li>
                                    <label className="container">
                                        <input type="radio" name="radio" />
                                        <span className="checkmark">
                                            <figure><img src={bookevepcard1} alt="" /></figure>
                                        </span>
                                    </label>
                                </li>
                                <li>
                                    <label className="container">
                                        <input type="radio" name="radio" />
                                        <span className="checkmark">
                                            <figure><img src={bookevepcard2} alt="" /></figure>
                                        </span>
                                    </label>
                                </li>
                                <li>
                                    <label className="container">
                                        <input type="radio" name="radio" />
                                        <span className="checkmark">
                                            <figure><img src={bookevepcard3} alt="" /></figure>
                                        </span>
                                    </label>
                                </li>
                                <li>
                                    <label className="container">
                                        <input type="radio" name="radio" />
                                        <span className="checkmark">
                                            <figure><img src={bookevepcard4} alt="" /></figure>
                                        </span>
                                    </label>
                                </li>
                            </ul> */}
                            <div className="order-summary">
                                <div className="head">
                                    <h4>Order Summary</h4>
                                </div>
                                <ul className="details">
                                    <li>
                                        <span className="property mr-3">
                                            Party Type
                                        </span>
                                        <span className="defaultValue">
                                            {reduxState.firstSaveData.e.slug}
                                        </span>
                                    </li>
                                    <li>
                                        <span className="property">
                                            Event Date
                                        </span>
                                        <span className="defaultValue">
                                            {reduxState.firstSaveData.e.event_date}
                                        </span>
                                    </li>
                                    <li>
                                        <span className="property">
                                            Start From
                                        </span>
                                        <span className="defaultValue">
                                            {reduxState.firstSaveData.e.start_from}
                                        </span>
                                    </li>
                                    <li>
                                        <span className="property">
                                            Number Of People
                                        </span>
                                        <span className="defaultValue">
                                            {reduxState.firstSaveData.e.estimated_guests}
                                        </span>
                                    </li>
                                    <li>
                                        <span className="property">
                                            Contact No
                                        </span>
                                        <span className="defaultValue">
                                            {reduxState.secondSaveData.e.phone_no}
                                        </span>
                                    </li>
                                    <li className="total">
                                        <span className="property">
                                            Total Amount
                                        </span>
                                        <span className="defaultValue" >
                                            {reduxState.firstSaveData.e.final_price}
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <div className="form-group">
                                {/* <input type="text"
                                    onChange={(e) => { setCardHolderName(e.target.value) }}
                                    className="form-control" placeholder="CARD HOLDER NAME" /> */}
                            </div>
                            <div>
                                {/* < handleClickOpen={handleClickOpen} /> */}
                                <Clover />
                                <p className="bold_txt_highlight"> We will make sure you and your guests have a great event!  Our truck will stay until everyone is served & satisfied.  $150 deposit now.  Our team will reach out a few days before to confirm details, special requests, & take final deposit.  You will receive an email confirmation after submitting payment.  Fully refundable 72 hours in advance.  </p> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EventThree;