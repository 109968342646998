import React from "react";
import '../../Assests/Css/fill.css';
import Footer from "../../ReuseableComponents/Footer";
import Header from "../../ReuseableComponents/Header";
import HeaderBanner from "../../ReuseableComponents/HeaderBanner";

const Fill = () => {
    return (
        <div className="Fill_Page">
            <Header />
            {/* <HeaderBanner name="fill" /> */}
            <section className="fill-info-sec">
                <div className="container">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="all-form filllllz">
                                    <div className="logo">
                                        <img className="img-fluid" src="img/logo.png" alt="" />
                                    </div>

                                    <div className="form-head">
                                        <h2>Fill your Information </h2>
                                        <h4>to get Free topping</h4>
                                    </div>
                                    <form action="">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <input type="text" placeholder="Name " className="form-control" />
                                                </div>

                                                <div className="form-group">
                                                    <input type="Email" placeholder="email" className="form-control" />
                                                </div>

                                                <div className="form-group">
                                                    <input type="text" placeholder="number" className="form-control" />
                                                </div>

                                                <div className="form-group">
                                                    <input type="text" placeholder="Address" className="form-control" />
                                                </div>

                                                <div className="form-group">
                                                    <button className="btn form-control ht-submzzz">Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </div>
    )
}

export default Fill;