import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { CheckCurrentEventApi, getBookingSelectApi, GetMoreTimeApi } from "../../Redux/Auth/Auth_Action";
import { toast } from 'react-toastify';

const HomeBannerContent1 = (props) => {
    let navigate = useNavigate();
    const ref = useRef();
    // const mobileref = useRef();
    const [state, setState] = useState({
        date: "",
        guestno: null,
        party_type: "",
        start_time: null,
        start_hour: "",
        start_min: "",
        duration: null,
        priceEval: "",
    });

    const [partyTypeDetails, setPartyTypeDetails] = useState();
    const [calculationItem, setCalculationItem] = useState();

    const InputVal = (e) => {
        let { name, value } = e.target;
        setState({ ...state, [name]: value });
    }

    const dispatch = useDispatch();
    const getBookingAPi = () => {
        dispatch(getBookingSelectApi())
            .then((res) => {
                setPartyTypeDetails(res.data.response.data);
                // setformPackages(res.data.response.data[0].slug);
                // console.log(res?.data?.response?.data, 'iiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii');
            }).catch((e) => {
                console.log(e);
            })
    }

    useEffect(() => {
        getBookingAPi();
    }, []);

    const FirstStepSend = (e) => {
        e.preventDefault();
        let Data = {
            event_date: state.date,
            start_from: `${state.start_hour + state.start_min}`,
            finish_by: "00:00 AM",
            // no_guest : state.guestno,
        }

        let saveDataFirstStep = {
            event_date: state.date,
            start_from: `${state.start_hour + state.start_min}`,
            // finish_by: formEndTime,
            slug: state.party_type,
            final_price: calculationItem,
            estimated_guests: state.guestno,
        }

        let passData = {
            formDate: state.date,
            formPartyType: state.party_type,
            formMoreTime: "",
            formStartTime: state.start_time,
            formEndTime: "",
            formPriceEval: calculationItem,
            getBookingSelect: "",
            getMoreTime: "",
        }
        if (!state.date || !state.start_min || !state.start_hour || !state.party_type || !state.guestno) {
            toast.error("Please fill all fields");
            // console.log("if condition works here");
        } else {
            // console.log("else condition works here");
            dispatch(CheckCurrentEventApi(Data, saveDataFirstStep))
                .then((res) => {
                    if (res.data.message === "Success!") {
                        // console.log("rexxzxzzzzz", res);
                        navigate('/bookevent', { state: { passData } })
                    } else {
                        navigate('/bookevent', { state: { passData } })
                        // toast.error("Another event is already booked on this date. Try another date for your event.");
                    }
                })
                .catch((e) => {
                    navigate('/bookevent', { state: { passData } })
                    // console.log('erroooooor', e);
                })
        }
    }

    const priceEvalFunc = () => {
        // formulae for both options , formula is dynamic controlled by admin
        // Open Truck Price = ($5.5 * Number Of Guests) + $150 + Tax
        // Cups Cones & Packaged Treats Price = ($4 * Number of Guests) + $150 + Tax
        if (state.party_type) {
            partyTypeDetails?.filter((item) => {
                if (item.slug === state.party_type) {

                    let calculateEvulationz = (parseFloat(item?.per_person) * parseFloat(state.guestno ? state.guestno : 1)) + parseFloat(item?.fixed_deposit);

                    let calulatedWithTax = (parseFloat(calculateEvulationz) / 100) * item?.tax;
                    let FinalVal = parseFloat(calculateEvulationz) + parseFloat(calulatedWithTax);
                    // console.log('calculateEvulationz',calculateEvulationz,"calulatedWithTax",calulatedWithTax ,"state.guestno",state.guestno, "ooooooooooooooooooooooo",item);
                    // return (
                    //     setCalculationItem(FinalVal.toFixed(2))
                    // )
                    if (state.guestno < 20) {
                        return (
                            setCalculationItem((FinalVal - 60).toFixed(2))
                        )
                    } else {
                        return (
                            setCalculationItem(FinalVal.toFixed(2))
                        )
                    }
                }
            })
        }
    }

    // console.log(calculationItem, "CalculationItem", partyTypeDetails);
    useEffect(() => {
        priceEvalFunc();
    }, [state]);

    // useEffect(()=>{
    //     if(window.screen.width < 600){
    //         mobileref.current.style.display = "none";
    //     }
    // },[]);

    return (
        <div className='HomeBannerContent1'>
            <div className="banner-form-wrapper form_one form_ghybb">
                <div className="banner-form-content-wrapper wow animate__animated animate__fadeInDown">
                    {/* <h3>events & happenings</h3> */}
                    {/* <h2>Book Cones App</h2> */}
                    <h2>Book Ice Cream Truck Parties</h2>
                    {/* <h5>THE BEST TIME FOR ICE CREAM IS ALWAYS</h5> */}
                    <h5>Instantly.Anywhere in Brooklyn & Long Island.  </h5>
                </div>
                <form onSubmit={FirstStepSend}>
                    <div className="form-group">
                        <input
                            type="text"
                            placeholder='DATE OF PARTY'
                            ref={ref}
                            onFocus={() => (ref.current.type = "date")}
                            required
                            onChange={InputVal}
                            value={state.date}
                            name="date"
                            min={new Date().toISOString().split('T')[0]}
                            className="form-control" />
                    </div>
                    <div className="form-group">
                        <input
                            value={state.guestno}
                            name="guestno"
                            placeholder="NUMBER OF GUESTS"
                            onChange={InputVal}
                            type='number'
                            className="form-control" />
                    </div>
                    <div className="form-group">
                        <select
                            className="form-control"
                            name="party_type"
                            value={state.party_type}
                            onChange={InputVal}
                        >
                            <option disabled selected value={""} >
                                PARTY TYPE
                            </option>
                            {partyTypeDetails?.map((item, index) => {
                                return (
                                    <option value={item?.slug} key={index + 10000}>
                                        {item?.title}
                                    </option>
                                )
                            })}
                        </select>
                    </div>
                    <div className="form-group row">
                        <div className="col-lg-4">
                            <label htmlFor="Start From">Start</label>
                            <select
                                required
                                className="form-control"
                                name="start_hour"
                                value={state.start_hour}
                                onChange={InputVal}
                            >
                                <option disabled selected value={""}>Hour</option>
                                <option value="13:">1 PM</option>
                                <option value="14:">2 PM</option>
                                <option value="15:">3 PM</option>
                                <option value="16:">4 PM</option>
                                <option value="17:">5 PM</option>
                                <option value="18:">6 PM</option>
                                <option value="19:">7 PM</option>
                                <option value="20:">8 PM</option>
                                <option value="21:">9 PM</option>
                                <option value="22:">10 PM</option>
                                <option value="23:">11 PM</option>
                                <option value="24:">12 PM</option>
                                <option value="1:">1 AM</option>
                                <option value="2:">2 AM</option>
                                <option value="3:">3 AM</option>
                                <option value="4:">4 AM</option>
                                <option value="5:">5 AM</option>
                                <option value="6:">6 AM</option>
                                <option value="7:">7 AM</option>
                                <option value="8:">8 AM</option>
                                <option value="9:">9 AM</option>
                                <option value="10:">10 AM</option>
                                <option value="11:">11 AM</option>
                                <option value="12:">12 AM</option>
                            </select>
                            {/* <input
                                        required
                                        name="start_time"
                                        value={state.start_time}
                                        onChange={InputVal}
                                        type='time'
                                        className="form-control" /> */}
                        </div>
                        {/* <div className="col-lg-4">
                                    <label htmlFor="START FROM">Party Duration</label>
                                    <div>
                                        <p className="from_end_time">+ {state.duration ? state.duration : 0} hours</p>
                                    </div>
                                    <input required onChange={(e) => { setformEndTime(e.target.value) }} type='text' disabled className="form-control" />
                                </div> */}
                        {/* <div className="col-lg-4 mt-rezz-evetnone"> */}
                        <div className="col-lg-4 finish-rezz-evetnone">
                        <label className="hidden-class" htmlFor="Start From">Finish</label>
                            <select
                                required
                                className="form-control"
                                name="start_min"
                                value={state.start_min}
                                onChange={InputVal}
                            >
                                <option disabled selected value={""}>Minute</option>
                                <option value="00">00</option>
                                <option value="15">15</option>
                                <option value="30">30</option>
                                <option value="45">45</option>
                            </select>
                        </div>
                        <div className="col-lg-4 mt-rez-prize">
                            <label htmlFor="Price Evaluation">Price</label>
                            <input type="text" disabled value={calculationItem && state.guestno && state.party_type ? `$ ${calculationItem}` : `$ ${0}`} className="form-control evu" />
                        </div>
                    </div>
                    <div className="form-group">
                        <button type="submit" className="btn form-control py-0 MB-HOME-CONT">Send</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default HomeBannerContent1;