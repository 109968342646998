import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { homechoose, homeweareimg } from '../../MediaConstants/Index';
import { HomeAboutUsApi } from '../../Redux/Auth/Auth_Action';

const AboutUsSec = () => {
    let navigate = useNavigate();
    const [about, setAbout] = useState();
    const dispatch = useDispatch();
    const GetAboutContent = () => {
        dispatch(HomeAboutUsApi())
            .then((res) => {
                // console.log(res);
                setAbout(res?.data?.response?.data);
                // console.log(res.data.response.data);
            })
            .catch((e) => {
                console.log(e);
            })
    }

    useEffect(() => {
        GetAboutContent();
        // GetAboutLocation();
    }, [])

    return (
        <>

            {about?.map((item, index) => {
                // console.log(item,'ppppppppppppp');
                if (index % 2 === 0) {
                    return (
                        <div key={index} className="row pb-5">
                            <div className="col-lg-5">
                                <div className="img-wrapper wow animate__animated animate__fadeInLeft">
                                <figure>
                                        <img src={item.media_url} className="img-fluid ht-img-home" alt="ice cream vandee" />
                                    </figure>
                                </div>
                            </div>
                            {/* <div className="col-lg-1">
                            </div> */}
                            <div className="col-lg-7">
                                <div className="content-wrapper wow animate__animated animate__fadeInRight txt_aboutSec_centr">
                                    {/* <h3>who we are</h3> */}
                                    {/* <img width={100} className="abt-immngaz" src={} /> */}
                                    {/* <h3>{item.main_heading}</h3> */}
                                    <h2>{item.title}</h2>
                                    {/* <h5>{item.sub_title}</h5> */}
                                    {/* <h5>we've been around for a while</h5> */}
                                    <div className='dngrslimit_home' dangerouslySetInnerHTML={{ __html: item.description }} >

                                    </div>
                                    {/* <div className='mt-5'>
                                        <button onClick={() => { navigate('/about') }} className='btn btn-light'>Read More</button>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    )
                } else {
                    return (
                        <div key={index} className="row pb-5">
                            <div className="col-lg-7">
                                <div className="content-wrapper wow animate__animated animate__fadeInRight txt_aboutSec_centr">
                                    {/* <h3>who we are</h3> */}
                                    {/* <img width={100} className="abt-immngaz" src={item.media_url} /> */}
                                    {/* <h3>{item.main_heading}</h3> */}
                                    <h2 >{item.title}</h2>
                                    {/* <h5>{item.sub_title}</h5> */}
                                    {/* <h5>we've been around for a while</h5> */}
                                    <div className='dngrslimit_home' dangerouslySetInnerHTML={{ __html: item.description }} >

                                    </div>
                                    {/* <div className='mt-5'>
                                        <button onClick={() => { navigate('/about') }} className='btn btn-light'>Read More</button>
                                    </div> */}
                                </div>
                            </div>
                            {/* <div className="col-lg-1">
                            </div> */}
                            <div className="col-lg-5">
                                <div className="img-wrapper wow animate__animated animate__fadeInLeft ">
                                  

                                    <figure>
                                        <img src={item.media_url} className="img-fluid ht-img-home" alt="ice cream vandee" />
                                    </figure>
                                </div>
                            </div>
                        </div>
                    )
                }
            })}
        </>
    )
}

export default AboutUsSec;