import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import '../../Assests/Css/profile.css';
import { profileimg } from "../../MediaConstants/Index";
import { toast } from 'react-toastify';
import { UpdateProfile } from "../../Redux/Auth/Auth_Action";

const ShowProfile = () => {

    const [showTab, setShowTab] = useState(1);
    const [uploadImage, setUploadImage] = useState("");
    const state = useSelector((state) => state.Auth);
    const dispatch = useDispatch();
    // console.log('taaaaaaaaaaaaaaa', state);
    const ChangeTab = (e) => {
        setShowTab(e);
        // console.log('from profile tabs  stzzzzzzzzzz', state);
        // console.log('from profile tabs', e);
    }

    const [profileData, setProfileData] = useState({
        first_name: state.user.first_name,
        last_name: state.user.last_name,
        email: state.user.email,
        phone: state.user.phone_no,
    });

    const [updateImage, setUpdateImage] = useState(state.user.profile_picture);

    const UploadImage = (event) => {
        if ((event.target.files[0].size / 1000000) <= 5) {
            // console.log("worksss")
            setUploadImage(event.target.files[0]);
            setUpdateImage(URL.createObjectURL(event.target.files[0]))
            // props.updateImage({ ...updateProfile, prof_img: event.target.files[0] })
            // console.log('sucess', (event.target.files[0].size / 1000000));
            // console.log(selectedImage,'selectedImageselectedImage')
        } else {
            toast.error("file size is greater than 5 mb");
        }
    }

    const InputVal = (e) => {
        let { name, value } = e.target;
        setProfileData({ ...profileData, [name]: value });
    }

    const UpdateProfileFunc = (e) => {
        // let Data = {
        //     first_name: profileData.first_name,
        //     last_name: profileData.last_name,
        //     phone_no: profileData.phone,
        //     avatar: updateImage,
        // }

        let profile = new FormData();    //formdata object
        profile.append('first_name', profileData.first_name);   //append the values with key, value pair
        profile.append('last_name', profileData.last_name);
        profile.append('avatar', uploadImage);
        profile.append('phone_no', profileData.phone);
        // Data state.token,
        dispatch(UpdateProfile(profile, state.token))
            .then((res) => {
                toast.success("Profile Updated Sucessfully")
            })
            .catch((e) => {
                console.log('gaaaaaaaaaaaaaaa', e)
            })
    }

    // console.log('assssss', profileData.phone)

    return (
        <div className="ShowProfile">
            <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel"
                aria-labelledby="v-pills-home-tab">
                <div className="profile-img-tab-wrapper">
                    <div className="img-content-wrapper">
                        <figure>
                            <img src={updateImage} className="img-fluid profile-img img-prf-rnddd" alt="" />
                            <button ><i className="fa fa-plus"><input className="inpt-img-upld" onChange={UploadImage} id="fileUpload" type="file" accept="image/*" /> </i></button>
                        </figure>
                        <div className="profile-name-content">
                            <h4>{state.user.first_name} {state.user.last_name}</h4>
                            {/* <h5>Lorem Ipsum</h5> */}
                        </div>
                    </div>
                    <div className="profile-button-wrappper">
                        <button onClick={() => { ChangeTab(2) }} className="btn edit-profile">Edit Profile</button>
                    </div>
                </div>
                <div className="profile-form-wrapper">
                    {(showTab === 1) &&
                        <>
                            <form action="">
                                <div className="form-group row">
                                    <div className="col-lg-6">
                                        <label for="">First Name</label>
                                        <input type="text" className="form-control" placeholder={`${state.user.first_name}`} disabled />
                                    </div>
                                    <div className="col-lg-6">
                                        <label for="">Email</label>
                                        <input type="email" className="form-control" placeholder={`${state.user.email}`}
                                            disabled />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-lg-6">
                                        <label for="">Last Name</label>
                                        <input type="text" className="form-control" placeholder={`${state.user.last_name}`} disabled />
                                    </div>
                                    <div className="col-lg-6">
                                        <label for="">Phone</label>
                                        <input type="number" className="form-control" placeholder={`${state.user.phone_no}`}
                                            disabled />
                                    </div>
                                </div>
                            </form>
                        </>
                    }
                </div>
                <div className="profile-edit-form-wrapper">
                    {
                        (showTab === 2) &&
                        <>
                            <form >
                                <div className="form-group row">
                                    <div className="col-lg-6">
                                        <label for="">First Name</label>
                                        <input type="text"
                                            value={profileData.first_name}
                                            name="first_name"
                                            onChange={InputVal}
                                            className="form-control" placeholder="John" />
                                    </div>
                                    <div className="col-lg-6">
                                        <label for="">Email</label>
                                        <input
                                            value={profileData.email}
                                            name="email"
                                            onChange={InputVal}
                                            disabled
                                            type="email" className="form-control" placeholder="Johndoe@email.com" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-lg-6">
                                        <label for="">Last Name</label>
                                        <input
                                            value={profileData.last_name}
                                            name="last_name"
                                            onChange={InputVal}
                                            type="text" className="form-control" placeholder="Doe" />
                                    </div>
                                    <div className="col-lg-6">
                                        <label for="">Phone</label>
                                        <input
                                            value={profileData.phone}
                                            name="phone"
                                            onChange={InputVal}
                                            type="number" className="form-control" placeholder="+00 123 456 789" />
                                    </div>
                                </div>
                                <div className="form-group mt-4">
                                    <button type="button" onClick={() => { UpdateProfileFunc(1) }} className="btn">Save Changes</button>
                                    <button onClick={() => { ChangeTab(1) }} className="btn ml-3 bg-rd-btn-prof">Cancel</button>
                                </div>
                            </form>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}
export default ShowProfile;