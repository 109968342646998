import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LogoutApiDelToken } from '../../Redux/Auth/Auth_Action';

const SideProfileTab = (props) => {
    const [optionTaken, setOptionTaken] = useState(1);
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const token = useSelector((state) => state.Auth.token)
    const checkFunc = (e) => {
        props.setChangeTabs(e);
        setOptionTaken(e);
    }

    // console.log('paaaaaaaaa', optionTaken);


    const Logout = () => {
        dispatch({ type: "DEL_TOKEN" });
        dispatch(LogoutApiDelToken(token))
            .then((res) => {
                navigate("/");
                // console.log("sucessfull logout");
            })
            .catch((e) => {
                navigate("/");
                console.log("unsucessfull logout");
            })
    }

    return (
        <div className='SideProfileTab_page'>
            <div className="profile-tabs-wrapper">
                <div className="profile-heading-wrapper">
                    <h4>My Profile</h4>
                </div>
                <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                    <a
                        onClick={() => { checkFunc(1) }} className={(optionTaken === 1) ? 'cursor_pointer nav-link active' : 'cursor_pointer nav-link'}
                    >My Profile</a>
                    <a
                        onClick={() => { checkFunc(2) }} className={(optionTaken === 2) ? 'cursor_pointer nav-link active' : 'cursor_pointer nav-link'}
                    >Security</a>
                    <a
                        onClick={() => { checkFunc(3) }} className={(optionTaken === 3) ? 'cursor_pointer nav-link active' : 'cursor_pointer nav-link'}
                    >My Bookings</a>
                    <a
                        onClick={Logout} className='cursor_pointer nav-link' >Log Out</a>
                </div>
            </div>
        </div>
    )
}

export default SideProfileTab;