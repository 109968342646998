import React, { useEffect, useState } from 'react';
import { aboutinnerbg, aboutwatermark, homeweare1, homeweare2, menumenu1, menumenu2, menumenu3, menumenu4, menumenu5, menumenu6 } from '../../MediaConstants/Index';
import Footer from '../../ReuseableComponents/Footer';
import Header from '../../ReuseableComponents/Header';
import '../../Assests/Css/menu.css';
import HeaderBanner from '../../ReuseableComponents/HeaderBanner';
import { useDispatch } from 'react-redux';
import { getMenuApi, getMenuPaginateApi } from '../../Redux/Auth/Auth_Action';

const Menu = () => {

    const [menu, setMenu] = useState();
    const [links, setLinks] = useState();

    const dispatch = useDispatch();
    const getMenuItems = () => {
        dispatch(getMenuApi())
            .then((res) => {
                setMenu(res.data.response.data.data);
                setLinks(res.data.response.data.links)
            })
            .catch((e) => {
                console.log('rezzzzz', e);
            })
    }

    useEffect(() => {
        getMenuItems();
        window.scroll(0,0);
    }, []);

    const MenuPagination = (e) => {
        dispatch(getMenuPaginateApi(e))
            .then((res) => {
                setMenu(res.data.response.data.data);
                setLinks(res.data.response.data.links)
            })
            .catch((e) => {
                console.log(e);
            })
        // console.log(';zzzasdasd',e);
    }


    return (
        <div className='MenuPage'>
            <Header />
            {/* <!-- Universal Banner Start Here --> */}
            <HeaderBanner name="Ice Cream Truck Menu" />
            {/* <!-- Universal Banner End Here --> */}

            {/* <!-- Watermark Image --> */}
            <div className="watermark">
                <figure><img src={aboutwatermark} className="img-fluid" /></figure>
            </div>
            {/* <!-- Watermark Image --> */}

            {/* <!-- Gallery Sec Start Here --> */}
            <section className="menu_sec">
                <div className="container">
                    <div className="row">
                        {menu?.map((item, index) => {
                            return (
                                <div key={index} className="col-lg-4">
                                   
                                        <div className="product_box">
                                            <div className="product_img">
                                                <figure><img src={item.media_url} className="img-fluid" /></figure>
                                            </div>
                                            <div className="product-detail">
                                                <div className="name">
                                                    {/* <h4>Our Menu</h4> */}
                                                    <h4>{item.title} </h4>
                                                    {/* <h4 className="price">{item.amount}$</h4> */}
                                                </div>
                                                <div className="description_manu" dangerouslySetInnerHTML={{ __html: item.description }}>
                                                </div>
                                            </div>
                                        </div>
                                   
                                </div>
                            )
                        })}
                    </div>

                    <div className='text-right'>
                        {links?.map((item, index) => {
                            if (item.label === "&laquo; Previous") {
                                item.label = "Previous";
                                return (
                                    <button key={index + 100}
                                        onClick={() => { MenuPagination(item.url) }}
                                        disabled={(item.url === null ? true : false)}
                                        className='btn btn-light bt-pagination-all'>
                                        {item.label}
                                    </button>
                                )
                            } else if (item.label === "Next &raquo;") {
                                item.label = "Next";
                                return (
                                    <button key={index + 100}
                                        onClick={() => { MenuPagination(item.url) }}
                                        disabled={(item.url === null ? true : false)}
                                        className='btn btn-light bt-pagination-all'>
                                        {item.label}
                                    </button>
                                )
                            } else {
                                return (
                                    <button key={index + 100}
                                        onClick={() => { MenuPagination(item.url) }}
                                        disabled={(item.url === null ? true : false)}
                                        className='btn btn-light bt-pagination-all'>
                                        {item.label}
                                    </button>
                                )
                            }
                        })}
                    </div>
                </div>
                <img src={homeweare1} className="we1" alt="" />
                <img src={homeweare2} className="we2" alt="" />
            </section>
            {/* <!-- Gallery Sec End Here --> */}
            <Footer />
        </div>
    )
}

export default Menu;