import React, { useEffect, useState } from 'react';
import { aboutlocation, } from '../../MediaConstants/Index';
import Slider from 'react-slick/lib/slider';
import '../../Assests/Css/Testimonialzaam.css';
import { GetAllBranchesInit, LocationApi, GetBranchApi } from '../../Redux/Auth/Auth_Action';
import { useDispatch } from 'react-redux';
// Testimonialzaam

const OurLocation = () => {

    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                // className='Slider_top_custom_arrows-next'
                style={{ ...style, display: "none", background: "red", height: "80px", width: "80px" }}
                onClick={onClick}
            />
        );
    }


    function SamplePrevArrow(props) {
        const { style, onClick } = props;
        return (
            <div
                // className="Slider_top_custom_arrows"
                style={{ ...style, display: "none", background: "green", height: "80px", width: "80px" }}
                onClick={onClick}
            />
        );
    }

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,

        appendDots: dots => (
            <div
                style={{
                    // backgroundColor: "black",
                    borderRadius: "10px",
                    color: "white",
                    padding: "10px"
                }}
            >
                <ul style={{ margin: "0px" }}> <span style={{ color: "white" }}>{dots}</span> </ul>
            </div>
        ),
    };

    const [location, setLocation] = useState();
    const [branches, setBranches] = useState();
    const [selectVal, setSelectVal] = useState();
    const dispatch = useDispatch();
    const GetAboutLocation = () => {
        dispatch(LocationApi())
            .then((res) => {
                setLocation(res.data.response.data);
            })
            .catch((e) => {
                console.log(e);
            })
    }

    const GetAllBranches = () => {
        dispatch(GetAllBranchesInit())
            .then((res) => {
                setBranches(res.data.response.data);
            })
            .catch((e) => {
                console.log(e);
            })
    }

    useEffect(() => {
        GetAboutLocation();
        GetAllBranches();
    }, [])

    const getSingleLocFunc = (e) => {
        setSelectVal(e.target.value);
        // console.log(e.target.value, 'asdasdasdasd')
        if (e.target.value === "0") {
            // console.log("working here");
            GetAllBranches();
        } else {
            // console.log("nottttt working here");
            dispatch(GetBranchApi(e.target.value))
                .then((res) => {
                    setBranches(res.data.response.data);
                })
                .catch((e) => {
                    console.log(e);
                })
        }
    }

    return (
        <>
            {/* <div className="row">
                <div className="col-md-4"></div>
                <div className="col-md-4">
                    <div className="location_box">
                        <div className="form-group">
                            <select value={selectVal} onChange={(e) => { getSingleLocFunc(e) }} className="form-control">
                                <option value={0}>All branches</option>
                                {location?.map((item, index) => {
                                    return (<option
                                        value={item.id}
                                        key={index}>
                                        {item.name}
                                    </option>)
                                })}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="col-md-4"></div>
            </div> */}
            {/* <div className='testizaam paamm'>
                {(branches)
                    ?
                    <Slider {...settings} className="slider_top_home">
                        {branches?.map((item, index) => {
                            console.log(item);
                            return (
                                <div key={index} className="all_locations">
                                    <div className="location_dv">
                                        <div className="location_img">
                                            <figure><img src={item.media_url} className="img-fluid img_location" /></figure>
                                        </div>
                                        <div className="content">
                                            <h4>{item.name}</h4>
                                            <ul>
                                                <li><a href={`tel:${item.contact_no}`}>{item.contact_no}</a></li>
                                                <li><a href={`mailto:${item.contact_email}`}>{item.contact_email}</a></li>
                                                <li>{item.contact_address}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </Slider>
                    :
                    <h1 className="text-center">Not Found in Your Area</h1>
                }
            </div> */}
            <div className='testizaam paamm'>
                <div className="all_locations">
                    <div className="location_dv">
                        {/* <div className="location_img">
                            <figure><img src={item.media_url} className="img-fluid img_location" /></figure>
                        </div> */}
                        <div className="content">
                            {/* <h4></h4> */}
                            <ul>
                                <li>Cones App On Demand <br /> 249 Smith Street #4004 <br /> Brooklyn, NY 11231</li>
                                <li><a href={`mailto:Owner@mistersofteeondemand.com`}>sales@conesapp.com</a></li>
                                <li><a href={`tel:917-818-4380`}>Call / Text : 917-818-4380</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OurLocation;