import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import {
  homeapple,
  homechoose,
  homecomming,
  homeconnect,
  homeCONTACTLESS,
  homegoogle,
  homeHYGIENE,
  homeicecrmsndwchch,
  homemedia1,
  homemobile,
  homemobile3,
  homeno,
  homeproname,
  homequote,
  homesimplefacbook,
  homeSLEEVED,
  homeweare1,
  homeweare2,
  homeweareimg,
} from "../../MediaConstants/Index";
import { GetSiteSettingsApi } from "../../Redux/Auth/Auth_Action";
import AboutUsSec from "./AboutUsSec";
import HomeBannerContent1 from "./HomeBannerContent1";
import HomeBannerContent2 from "./HomeBannerContent2";
import TestimonialItems from "./TestimonialItems";
import TopSliderHome from "./TopSliderHome";
import TruckCleanessHome from "./TruckCleanessHome";
// import { FacebookProvider, EmbeddedPost } from 'react-facebook';
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LazyLoad from "react-lazy-load";

const HomeContent = () => {
  const navigate = useNavigate();
  const [changeHomeBanner, setChangeHomeBanner] = useState(1);
  const [widthChecked, setWidthChecked] = useState(null);

  const FunctionChangeBanner = (e) => {
    setChangeHomeBanner(e);
  };

  const [siteData, setSiteData] = useState(null);
  const dispatch = useDispatch();
  const getSiteSetting = () => {
    dispatch(GetSiteSettingsApi())
      .then((res) => {
        setSiteData(res?.data?.response?.data[0]);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const checkWidth = () => {
    if (window.screen.width >= 575) {
      setWidthChecked(500);
    } else {
      setWidthChecked(320);
    }
  };

  // console.log('555555555555555555555555555555', widthChecked);

  useEffect(() => {
    getSiteSetting();
    window.scroll(0, 0);
    checkWidth();
  }, []);
  return (
    <div className="HomeContent">
      {/* <!-- banner starts here --> */}
      <section className="banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 ">
              <div className="home_top_slider">
                <TopSliderHome setChangeHomeBanner={setChangeHomeBanner} />
              </div>
              {/* <div className="banner-content-wrapper wow animate__animated animate__fadeInLeft">
                                <h2>Get It on the <span>fun</span></h2>
                                <h1>Mister Softee</h1>
                                <p>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. <br /> Lorem Ipsum has
                                    been the industry's standard dummy text ever since the <br /> 1500s,
                                </p>
                                <Link to="/about" className="btn">Learn More</Link>
                                <button
                                    onClick={() => { FunctionChangeBanner(2) }}
                                    className="btn free_topping ml-2">Get Free Topping</button>
                            </div> */}
            </div>
            <div className="col-lg-5">
              {changeHomeBanner === 1 && (
                <HomeBannerContent1
                  FunctionChangeBanner={FunctionChangeBanner}
                />
              )}
              {changeHomeBanner === 2 && (
                <HomeBannerContent2
                  FunctionChangeBanner={FunctionChangeBanner}
                />
              )}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- banner ends here -->
            <!-- who we are sec starts here --> */}
      <section className="who-we-are">
        <div className="container">
          <AboutUsSec />
        </div>
        <img src={homeweare2} className="we1" alt="" />
        <img src={homeweare1} className="we2" alt="" />
      </section>
      {/* <!-- who we are sec ends here -->
                    <!-- cleanliness starts here --> */}
      <section className="cleanliness center_truckclean_responsv">
        <div className="container">
          <div className="row pb-5">
            <div className="col-lg-12">
              <div className="content-wrapper clen text-center wow animate__animated animate__fadeInDown ">
                <h3>Truck Cleanliness Is Our Priority</h3>
                <h2>Here’s What We Do To Keep You Healthy</h2>
              </div>
            </div>
          </div>
          <TruckCleanessHome />
        </div>
      </section>
      {/* <!-- cleanliness ends here -->
                    <!-- connect starts here --> */}
      <section className="connect">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center mb-5 wow animate__animated animate__fadeInDown">
              <div className="main-content-wrapper">
                <h3>Connect With Us</h3>
                <h2>We are Social</h2>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="content-wrapper wow animate__animated animate__fadeInLeft mb-res-testihead">
                <h2>
                  Follow Us On <br /> Instagram & Facebook
                </h2>
                <p>
                  Stay up to date on our innovative new concept and special
                  offers.
                </p>
                {/* on the Cones App's social media accounts. */}
                {/* <a href={`${siteData?.facebook}`}></a> */}
                <a href={`${siteData?.facebook}`} target="_blank">
                  <FacebookIcon className=" cursor_pointer mr-2" />
                </a>
                <a href={`${siteData?.instagram}`} target="_blank">
                  <InstagramIcon className="cursor_pointer mr-2" />
                </a>
              </div>
            </div>
            <div className="col-lg-7">
              <LazyLoad>
                <div className="media-wrapper">
                  <div className="row">
                    <div className="offset-lg-2 col-lg-10 text-center">
                      <div className="card-wrapper">
                        <iframe
                          src={`https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fmistersofteeondemand%2F&tabs=timeline&width=${widthChecked}&height=500&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=false&appId`}
                          height="500"
                          width={widthChecked}
                          style={{
                            margin: "auto",
                            border: "0",
                            overflow: "none",
                            width: "100%",
                          }}
                          scrolling="no"
                          frameBorder="0"
                          allowFullScreen="true"
                          allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                        ></iframe>
                        {/* last one */}
                        {/* <iframe className='m-auto' src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fmistersofteeondemand%2F&tabs=timeline&width=500&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=false&appId" height="500" width={`320`} scrolling="no" frameBorder="0" allowFullScreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe> */}
                      </div>
                    </div>
                  </div>
                </div>
              </LazyLoad>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- connect ends here --> */}

      {/* <!-- comming starts here --> */}
      <section className="coming">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <figure>
                <img
                  src={homemobile3}
                  className="img-fluid wow animate__animated animate__tada"
                  alt=""
                />
              </figure>
            </div>
            <div className="col-lg-8 align-self-center">
              <div className="content-wrapper wow animate__animated animate__fadeInRight">
                <h4>Available Now </h4>
                <h1>
                  CONES <span>APP</span>
                </h1>
                <p>
                  What can be more exciting than ordering your favorite ice
                  cream truck via a mobile application?{" "}
                </p>
                <p>
                  Whether you want ice cream at your wedding, birthday party,
                  workplace, or school fundraiser, we got you covered.{" "}
                </p>
                {/* <div>
                                    <button onClick={() => { navigate('/bookevent') }} className="btn mb-4">ICE CREAM AT YOUR DOOR? YES PLEASE.</button>
                                </div> */}
                <a href="https://apps.apple.com/us/app/cones-app/id6443475272">
                  <img
                    src={homeapple}
                    className="img-fluid mr-1 img_res_msall"
                    alt=""
                  />
                </a>
                {/* <a href="https://play.google.com/store/apps/details?id=com.mistersofteeondemand"> */}
                <a target="_blank" href="https://play.google.com/store/apps/details?id=com.conesapp">
                  <img
                    src={homegoogle}
                    className="img-fluid img_res_msall"
                    alt=""
                  />
                </a>
                {/* <Link to="/bookevent">
                                    ICE CREAM AT YOUR DOOR? YES PLEASE.
                                </Link> */}
              </div>
            </div>
          </div>
        </div>
        <img src={homecomming} className="ice" alt="" />
      </section>
      {/* <!-- comming ends here --> */}

      {/* <!-- testimonails starts here --> */}
      <section className="testimonials">
        <img src={homeconnect} className="connec" alt="" />
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 className="mb-5 mb-res-testihead">
                Cones App Client Testimonials
              </h2>
            </div>
            <div className="col-lg-12 text-center ">
              <div className="header-btn mb-5 mb-res-testihead">
                <Link to="/menu" className="btn">
                ICE CREAM AT YOUR DOOR? YES PLEASE
                </Link>
                {/* <Link to="/ondemand" className="btn demand">On demand</Link> */}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="content-wrapper wow animate__animated animate__fadeInDown">
                <h3>Their Words. Our Motivation.</h3>
                {/* <h2>Here’s what our customers say about us. </h2> */}
                <p>Here’s what our customers say about us.</p>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="testimonails-wrapper">
                {/* 3 time same */}

                <TestimonialItems />
              </div>
              <div className="img-wrapper">
                <img src={homeicecrmsndwchch} className="sandwish" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- testimonails ends here --> */}
    </div>
  );
};

export default HomeContent;
