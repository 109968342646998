import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { profileimg } from "../../MediaConstants/Index";
import { getBookingDataApi, getBookingPaginationApi } from "../../Redux/Auth/Auth_Action";

const MyBookings = () => {
    const state = useSelector((state) => state.Auth);
    const [bookingData, setBookingData] = useState();
    const [links, setLinks] = useState();
    const ReduxToken = useSelector((state) => state.Auth);
    const dispatch = useDispatch();
    const getBookingData = () => {
        dispatch(getBookingDataApi(ReduxToken.token))
            .then((res) => {
                setBookingData(res?.data?.response?.data?.data);
                setLinks(res?.data?.response?.data?.links);
                // console.log(res.data.response.data.data);
            })
            .catch((e) => {
                console.log(e);
            })
    }

    useEffect(() => {
        getBookingData();
    }, [])


    const BookingPagination = (e) => {
        dispatch(getBookingPaginationApi(e,ReduxToken.token))
            .then((res) => {
                setBookingData(res?.data?.response?.data?.data);
                setLinks(res?.data?.response?.data?.links);
            })
            .catch((e) => {
                console.log(e);
            })
        // console.log(';zzzasdasd',e);
    }

    return (
        <div className="MyBookings_TAB">
            <div  >
                <div >
                    <div className="img-content-wrapper">
                        <figure>
                            <img src={state.user.profile_picture} className="img-fluid profile-img img-prf-rnddd book-prof-imgggg" alt="" />
                            {/* <button className="butnnnn"> <i className="fa fa-plus"></i></button> */}
                        </figure>
                        <div className="profile-name-content">
                            <h4>{state.user.first_name} {state.user.last_name}</h4>
                            {/* <h5></h5> */}
                        </div>
                    </div>
                    {/* <!-- <div className="profile-button-wrappper">
<button className="btn edit-profile">Edit Profile</button>
</div> --> */}
                </div>
                <div className="booking_tab">
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th className="date text-center">Date</th>
                                    <th className="timing text-center">Timing</th>
                                    <th className="location text-center">Amount</th>
                                    <th className="package text-center">Phone No</th>
                                    {/* <th className="status text-center">Status</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {bookingData?.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td className="date text-center">{item?.event_date}</td>
                                            <td className="timing text-center">{item?.start_from.slice(0, 5)} - {item?.finish_by.slice(0, 5)}</td>
                                            <td className="location text-center">{item?.amount}</td>
                                            <td className="package text-center">{item?.phone_no}</td>
                                            {/* <td className="status text-center"><a href="#">{item?.status}</a></td> */}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>

                    <div className="text-right mt-4">
                        {links?.map((item, index) => {
                            if (item.label === "&laquo; Previous") {
                                item.label = "Previous";
                                return (
                                    <button key={index + 100}
                                        onClick={() => { BookingPagination(item.url) }}
                                        disabled={(item.url === null ? true : false)}
                                        className='btn btn-light bt-pagination-all'>
                                        {item.label}
                                    </button>
                                )
                            } else if (item.label === "Next &raquo;") {
                                item.label = "Next";
                                return (
                                    <button key={index + 100}
                                        onClick={() => { BookingPagination(item.url) }}
                                        disabled={(item.url === null ? true : false)}
                                        className='btn btn-light bt-pagination-all'>
                                        {item.label}
                                    </button>
                                )
                            } else {
                                return (
                                    <button key={index + 100}
                                        onClick={() => { BookingPagination(item.url) }}
                                        disabled={(item.url === null ? true : false)}
                                        className='btn btn-light bt-pagination-all'>
                                        {item.label}
                                    </button>
                                )
                            }
                        })}
                    </div>

                    {/* <ul className="pagination">
                        <li><a href="#" className="active">1</a></li>
                        <li><a href="#">2</a></li>
                        <li><a href="#">3</a></li>
                        <li><a href="#" className="more">...</a></li>
                        <li><a href="#">4</a></li>
                        <li><a href="#" className="btn">Next</a></li>
                    </ul> */}
                </div>
            </div>
        </div>
    )
}

export default MyBookings;