import React, { useEffect, useState } from 'react';
// import { } from '../../MediaConstants/Index';
import Footer from '../../ReuseableComponents/Footer';
import Header from '../../ReuseableComponents/Header';
import HeaderBanner from '../../ReuseableComponents/HeaderBanner';
import '../../Assests/Css/contact.css';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { ContactApi, GetSiteSettingsApi } from '../../Redux/Auth/Auth_Action';

const Contact = () => {
    const dispatch = useDispatch();
    const [siteData, setSiteData] = useState(null);
    const [state, setState] = useState({
        name: "",
        email: "",
        contact: "",
        message: "",
    });

    const InputVal = (e) => {
        let { name, value } = e.target;
        setState({ ...state, [name]: value });
    }

    const getSiteSetting = () => {
        dispatch(GetSiteSettingsApi())
            .then((res) => {
                setSiteData(res?.data?.response?.data[0])
            })
            .catch((e) => {
                console.log(e);
            })
    }

    useEffect(() => {
        getSiteSetting();
    }, [])

    const SubmitData = (e) => {
        e.preventDefault();
        let ContactData = {
            name: state.name,
            email: state.email,
            phone: state.contact,
            message: state.message,
        }

        dispatch(ContactApi(ContactData))
            .then((res) => {
                // console.log(res);
                toast.success("Your message has been sent successfully");
                setState({
                    ...state,
                    name: "",
                    email: "",
                    contact: "",
                    message: "",
                })
            })
            .catch((e) => {
                console.log(e);
            })
    }

    useEffect(() => {
        window.scroll(0, 0);
    }, [])

    return (
        <div className='ContactPage'>
            <Header />
            <HeaderBanner name="Contact Us" />
            {/* <!-- Contact Info Start Here --> */}
            <section className="contact_info">
                <div className="container">
                    <div className="heading_wrapper">
                        <h3>contact us</h3>
                        <h2>How can we help?</h2>
                    </div>
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="info_box">
                                <div className="icon_box">
                                    <i className="fa fa-phone" aria-hidden="true"></i>
                                </div>
                                <div className="content">
                                    <h5>Phone / Text</h5>
                                    <ul>
                                        <li> {siteData?.contact_phone}</li>
                                        {/* <li>Fax : {siteData?.contact_fax}</li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="info_box">
                                <div className="icon_box">
                                    <i className="fa fa-envelope" aria-hidden="true"></i>
                                </div>
                                <div className="content">
                                    <h5>Email</h5>
                                    <ul>
                                        {/* <li>{siteData?.contact1_email}</li> */}
                                        <li>{siteData?.contact2_email}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="info_box">
                                <div className="icon_box">
                                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                                </div>
                                <div className="content">
                                    <h5>Address</h5>
                                    <ul>
                                        {/* <li>{siteData?.address}</li> */}
                                        <li>Cones App On Demand <br /> 249 Smith Street #4004 <br /> Brooklyn, NY 11231</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- Contact Info Start Here -->

            <!-- Contact Sec Css Start Here --> */}
            <section className="contact_sec">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="google_map">
                                <iframe
                                    className='mappam'
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3025.578518241822!2d-73.99479868467232!3d40.683254847310685!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25a50c4a763cf%3A0xe91dd709bde39294!2s249%20Smith%20St%2C%20Brooklyn%2C%20NY%2011231%2C%20USA!5e0!3m2!1sen!2s!4v1661509712086!5m2!1sen!2s"
                                    loading="lazy"
                                    height="500"
                                    width="100%"
                                //   style="border:0;" allowFullScreen="" 
                                // referrerPolicy="no-referrer-when-downgrade"
                                ></iframe>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="contact_form">
                                <div className="heading_wrapper">
                                    <h3>contact form</h3>
                                    {/* <h2>Book Mister Softee</h2> */}
                                </div>
                                <form onSubmit={SubmitData} method="POST">
                                    <div className="form-group">
                                        <input
                                            value={state.name || ""}
                                            name="name"
                                            required
                                            onChange={InputVal}
                                            type="text" placeholder="Your Name" className="form-control" />
                                    </div>
                                    <div className="form-group">
                                        <input
                                            required
                                            value={state.email || ""}
                                            name="email"
                                            onChange={InputVal}
                                            type="email" placeholder="Email" className="form-control" />
                                    </div>
                                    <div className="form-group">
                                        <input
                                            required
                                            value={state.contact || ""}
                                            name="contact"
                                            onChange={InputVal}
                                            type="number" placeholder="Phone no" className="form-control" />
                                    </div>
                                    <div className="form-group">
                                        <textarea
                                            required
                                            value={state.message || ""}
                                            name="message"
                                            onChange={InputVal}
                                            placeholder="Message" className="form-control"></textarea>
                                    </div>
                                    <div className="button-group">
                                        <button className="btn" type="submit">Send</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- Contact Sec Css End Here --> */}
            <Footer />
        </div>
    )
}

export default Contact;